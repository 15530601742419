import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../AppContext";

function AlbumCard(props) {
  const { globalState, setGlobalState } = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-col border-custom-text-gray/40 items-left p-4 rounded-3xl space-y-3 hover:bg-custom-primary/40 px-6 hover:cursor-pointer w-max"
      onClick={() => {
        console.log("Setting Album ID: ", props.albumId);
        setGlobalState({
          ...globalState,
          authorAlbumView: props.albumId,
        });
        navigate("/view-album");
      }}
    >
      <img
        src={props.albumCover}
        className="md:w-28 lg:w-28  md:h-28 lg:h-28 w-20 h-auto rounded-xl"
      />
      <div className="flex flex-col items-left">
        <p className="text-sm md:text-md lg:text-md">{props.albumName}</p>
        <p className="text-custom-text-gray text-xs mt-1 md:text-sm lg:text-sm capitalize">
          {props.authorName}
        </p>
      </div>
    </div>
  );
}

export default AlbumCard;
