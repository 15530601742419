import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../AppContext";
import { useNavigate } from "react-router-dom";

import Titlebar from "../../components/Titlebar";
import ArtistCard from "../../components/ArtistCard";

function TopArtists(props) {
  const { globalState } = useContext(AppContext);
  const [artistsCards, setArtistsCards] = useState([]);
  const navigate = useNavigate();

  const processArtistsCards = () => {
    let tempArr = [];
    if (globalState.allAuthors.length > 0) {
      const card = globalState.allAuthors.map((author) => {
        return (
          <ArtistCard
            artistName={author.name}
            artistId={author._id}
            image={author.profileData.profilePicture}
          />
        );
      });
      tempArr.push(card);
      setArtistsCards(tempArr);
    }
  };

  useEffect(() => {
    processArtistsCards();
  }, [globalState.allAuthors]);
  return (
    <>
      <Titlebar pageTitle="Explore Artists" showSearchBar={false}/>
      <div className="flex flex-col lg:justify-between items-center p-4 px-6 bg-custom-white rounded-3xl mt-6 md:w-full md:p-8 lg:w-full lg:p-8 w-full">
        {/* <div className="flex flex-row justify-between w-full items-center ">
          <p className="text-md font-bold">Explore Artists</p>
        </div> */}
        {artistsCards.length > 0 ? (
          <div className="grid grid-cols-2 md:grid-cols-3  lg:grid-cols-5 w-full ">
            {artistsCards}
          </div>
        ) : (
          <div>
            <p className="text-xl font-bold text-black/50">No Artists</p>
          </div>
        )}
      </div>
    </>
  );
}

export default TopArtists;
