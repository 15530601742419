// Homepage.js
import React, { useContext, useEffect } from "react";
import { AppContext } from "../AppContext";
import Navbar from "./Navbar";

function Homepage(props) {
  const { globalState, setGlobalState, tools, checkJWTStatus } =
    useContext(AppContext);

  useEffect(() => {
    async function checkJWT() {
      console.log("Checking user authentication...");
      setGlobalState((prevState) => ({
        ...prevState,
        loading: {
          status: true,
          message: "Checking user Authentication...",
          error: false,
        },
      }));
      const checkAuth = await checkJWTStatus("Homepage");
      if (checkAuth === true) {
        console.log("User is authenticated");
        setGlobalState((prevState) => ({
          ...prevState,
          loading: {
            status: false,
            message: "",
            error: false,
          },
        }));
        tools.navigate("/dashboard");
      } else {
        console.log("User is not authenticated");
        setGlobalState((prevState) => ({
          ...prevState,
          loading: {
            status: false,
            message: "",
            error: false,
          },
        }));
        tools.navigate("/login");
      }
    }
    checkJWT();
  }, []);

  return (
    <>
      <p>Welcome</p>
    </>
  );
}

export default Homepage;
