import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";

import Titlebar from "../../components/Titlebar";
import artistProfileSongs from "../../assets/icons/artistProfileSongs.svg";
import calendarIcon from "../../assets/icons/calendar.svg";
import Tag from "../../components/Tag";
import AddressDisplay from "../../components/AddressDisplay";

import InputOne from "../../components/InputOne";
import ButtonOne from "../../components/ButtonOne";

function UserProfile(props) {
  const { globalState, checkJWTStatus, updateUserObject } =
    useContext(AppContext);
  const navigate = useNavigate();
  const [modName, setModName] = useState("");
  const [modEmail, setModEmail] = useState("");

  const initAuthCheck = async () => {
    console.log("[+] Checking if user is authenticated", globalState);
    if (globalState.isAuthenticated === false) {
      console.log("[+] User is not authenticated");
      const status = await checkJWTStatus("Author Profile");
      if (status === false) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    initAuthCheck();
  }, []);

  return (
    <>
      <Titlebar
        showIcon={false}
        showSearchBar={false}
        pageTitle={"Your Profile"}
      />
      <div className="flex flex-col lg:justify-between items-center p-4 px-6 bg-custom-white rounded-3xl mt-6 md:w-full md:p-8 lg:w-full lg:p-8">
        <div className="flex flex-row justify-start w-full items-center lg:items-start md:items-start ">
          <div className="flex flex-col justify-start ml-4 w-full lg:space-y-4 md:space-y-3 space-y-2">
            <div className="flex flex-row  w-full space-x-4">
              <p className="md:text-4xl  lg:text-5xl font-extrabold">
                {globalState.userObject.name}
              </p>
            </div>
            <Tag label={globalState.userObject.role} songCountDisplay={false} />
            <div className="flex lg:flex-row flex-col  lg:justify-between items-start  lg:space-x-8 space-y-4 lg:pt-0 pt-2">
              <div className="flex flex-row space-x-8">
                <div className="flex flex-row justify-center space-x-2 items-center">
                  <img
                    src={calendarIcon}
                    alt="joinDate"
                    className="lg:w-6 md:h-6 w-4 h-4"
                  />
                  <p className="text-custom-text-gray lg:text-sm md:text-sm text-xs">
                    17 Apr, 2023
                  </p>
                </div>
                <div className="flex flex-row justify-center space-x-2 items-center">
                  <img
                    src={artistProfileSongs}
                    alt="artistProfileSong"
                    className="lg:w-6 md:h-6 w-4 h-4"
                  />
                  <p className="text-custom-text-gray lg:text-sm md:text-sm text-xs">
                    {globalState.likedSongs.length} Liked Songs
                  </p>
                </div>
              </div>
              {/* <AddressDisplay /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col p-8 rounded-3xl bg-custom-white mt-6">
        <p className="text-md font-bold mb-6">Top Songs</p>
        <div className="flex flex-col space-y-4 w-fit">
          <InputOne
            placeholder="Enter Name"
            onInput={(e) => {
              console.log("Name: ", e.target.value);
              setModName(e.target.value);
            }}
            value={modName}
            name="modName"
          />
          {/* <InputOne
            placeholder="Enter Email"
            onInput={(e) => {
              console.log("Email: ", e.target.value);
              setModEmail(e.target.value);
            }}
            value={modEmail}
            name="modEmail"
          /> */}
        </div>
        <div className="flex flex-row justify-between w-full mt-8">
          <ButtonOne
            label="Save Changes"
            onClick={() => {
              let tempUserObject = globalState.userObject;
              if (modName !== "") {
                tempUserObject.name = modName;
                updateUserObject(tempUserObject);
                setModName("");
              }
              if (modEmail !== "") {
                tempUserObject.email = modEmail;
                updateUserObject(tempUserObject);
                setModEmail("");
              }
            }}
          />
          <ButtonOne
            alert={true}
            label="Delete Account"
            // onClick={() => connectWallet()}
          />
        </div>
      </div>
    </>
  );
}

export default UserProfile;
