const contractabi = `[
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "string",
				"name": "songId",
				"type": "string"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "songCount",
				"type": "uint256"
			}
		],
		"name": "SongPlayed",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "songId",
				"type": "string"
			}
		],
		"name": "getSong",
		"outputs": [
			{
				"components": [
					{
						"internalType": "string",
						"name": "songId",
						"type": "string"
					},
					{
						"internalType": "string",
						"name": "albumId",
						"type": "string"
					},
					{
						"internalType": "uint256",
						"name": "songCount",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "royaltyRate",
						"type": "uint256"
					},
					{
						"components": [
							{
								"internalType": "uint256",
								"name": "blockTimestamp",
								"type": "uint256"
							},
							{
								"internalType": "bytes32",
								"name": "transactionHash",
								"type": "bytes32"
							},
							{
								"internalType": "address",
								"name": "callerAddress",
								"type": "address"
							}
						],
						"internalType": "struct MusicContract.Transaction[]",
						"name": "transactions",
						"type": "tuple[]"
					}
				],
				"internalType": "struct MusicContract.Song",
				"name": "",
				"type": "tuple"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "songId",
				"type": "string"
			}
		],
		"name": "playSong",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"name": "songs",
		"outputs": [
			{
				"internalType": "string",
				"name": "songId",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "albumId",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "songCount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "royaltyRate",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "songId",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "newRoyaltyRate",
				"type": "uint256"
			},
			{
				"internalType": "string",
				"name": "albumId",
				"type": "string"
			}
		],
		"name": "updateRoyaltyRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
]`;

export default contractabi;
