import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AppContext } from "../../AppContext";

import albumcover from "../../assets/albumcover_sample.png";
import artistProfileAlbum from "../../assets/icons/artistProfileAlbum.svg";
import artistProfileSongs from "../../assets/icons/artistProfileSongs.svg";
import verified from "../../assets/icons/verified.svg";
import Tag from "../../components/Tag";
import ArtistCard from "../../components/ArtistCard";
import DetailedSongCard from "../../components/DetailedSongCard";

import Titlebar from "../../components/Titlebar";
import TopArtists from "../../components/TopArtists";
import TopSongs from "../../components/TopSongs";
import LikedSongs from "../../components/LikedSongs";

function ContributorDashboard(props) {
  const {
    globalState,
    checkJWTStatus,
    imageConverter,
    getAlbumsOfAuthor,
    getContributorData,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [totalRoyaltyEarned, setTotalRoyaltyEarned] = useState(0);
  const [totalAlbums, setTotalAlbums] = useState(0);
  const [songCards, setSongCards] = useState([]);

  const calculateRoyaltyEarned = (res) => {
    if (res !== undefined || res.songContributions.length !== 0) {
      let total = 0;
      let tempArr = [];
      res.songContributions.forEach((song) => {
        let percent = parseInt(song.royaltyPercentage);
        let calValue = parseFloat((percent / 100) * song.royalty);
        total = parseFloat(calValue) + total;
        console.log("total", total);
        setTotalRoyaltyEarned(total.toFixed(2));

        tempArr.push(
          <DetailedSongCard
            songId={song.songId}
            songName={song.songName}
            albumName={song.albumName}
            authorName={song.albumName}
            royalty={song.royalty}
            royaltyPercentage={song.royaltyPercentage}
            songImage={song.songCover}
            playCount={song.playCounts}
            showReleased={true}
            releaseDate={song.releaseDate}
            showRoyaltyPercentage={true}
            showPlayCount={true}
            contributeValue={parseFloat(
              song.royalty * (parseFloat(song.royaltyPercentage) / 100)
            ).toFixed(2)}
            isContributor={true}
            songUrl={song.songUrl}
          />
        );
        setSongCards(tempArr);
      });
      const uniqueAlbumNames = new Set(
        res.songContributions.map((song) => song.albumName)
      );
      const countOfUniqueAlbums = uniqueAlbumNames.size;
      setTotalAlbums(countOfUniqueAlbums);
    }
  };

  const initAuthCheck = async () => {
    console.log("[+] Checking if user is authenticated", globalState);
    if (globalState.isAuthenticated === false) {
      console.log("[+] User is not authenticated");
      const status = await checkJWTStatus("Author Dashoboard");
      if (status === false) {
        navigate("/login");
      } else {
        let cData = await getContributorData();
        console.log("cData", cData);
        calculateRoyaltyEarned(cData);
      }
    } else {
      let cData = await getContributorData();
      console.log("cData", cData);
      calculateRoyaltyEarned(cData);
    }
  };

  useEffect(() => {
    initAuthCheck();
  }, []);

  return (
    <>
      <Titlebar showSearchBar={false} />
      <div className="flex flex-col items-center p-4 px-6 mt-6 lg:justify-between bg-custom-white rounded-3xl md:w-full md:p-8 lg:w-full lg:p-8">
        <div className="flex flex-row items-center justify-start w-full lg:items-start md:items-start ">
          {/* <img
            src={albumcover}
            alt="albumcover"
            className="w-20 h-20 lg:w-36 lg:h-36 md:w-28 md:h-28 rounded-2xl"
          /> */}
          <div className="flex flex-col justify-start w-full ml-4 space-y-2 lg:space-y-4 md:space-y-3">
            <div className="flex flex-row w-full space-x-4">
              <p className="font-extrabold capitalize md:text-4xl lg:text-5xl">
                {globalState.userObject.name}
              </p>
              <img src={verified} alt="verified" className="w-6 h-6" />
            </div>
            <Tag
              label={globalState.contributorData.counts}
              songCountDisplay={true}
            />
            <div className="flex flex-row space-x-8 justify-left">
              <div className="flex flex-row items-center justify-center space-x-2">
                <img
                  src={artistProfileAlbum}
                  alt="artistProfileAlbum"
                  className="w-4 h-4 lg:w-6 md:h-6"
                />
                <p className="text-xs text-custom-text-gray lg:text-sm md:text-sm">
                  {totalAlbums} Albums
                </p>
              </div>
              <div className="flex flex-row items-center justify-center space-x-2">
                <img
                  src={artistProfileSongs}
                  alt="artistProfileSong"
                  className="w-4 h-4 lg:w-6 md:h-6"
                />
                <p className="text-xs text-custom-text-gray lg:text-sm md:text-sm">
                  {globalState.contributorData.totalSongs} Songs
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col p-4 px-6 mt-6 bg-custom-white rounded-3xl md:w-full md:p-8 lg:w-full lg:p-8">
        <p className="font-bold text-md">Stats</p>
        <div className="flex flex-row justify-between px-12 mt-6 space-x-8">
          <div className="flex flex-row items-end space-x-4">
            <svg
              width="35"
              height="40"
              viewBox="0 0 35 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mb-1"
            >
              <path
                d="M34.8017 6.22164V28.3397C34.8017 32.2152 31.6504 35.3666 27.7748 35.3666C23.9188 35.3666 20.7479 32.2152 20.7479 28.3397C20.7479 24.4837 23.9188 21.3324 27.7748 21.3324C29.419 21.3324 30.887 21.9 32.081 22.8395V11.1933L14.0538 16.3216V32.1174C14.0538 35.9929 10.8828 39.1442 7.02686 39.1442C3.15131 39.1442 0 35.9929 0 32.1174C0 28.2614 3.15131 25.1101 7.02686 25.1101C8.65148 25.1101 10.1195 25.6777 11.3135 26.5976V9.29468C11.3135 6.41737 13.0555 4.186 15.8154 3.4422L27.1289 0.349592C29.419 -0.27676 31.3568 -0.0614518 32.7269 0.995517C34.1166 2.03291 34.8017 3.79453 34.8017 6.22164Z"
                fill="#FF9A45"
              />
            </svg>
            <div className="flex flex-col">
              <p className="text-sm text-custom-text-gray ">Songs</p>
              <p className="text-5xl font-bold">
                {globalState.contributorData.totalSongs}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-end space-x-4">
            <svg
              width="41"
              height="42"
              viewBox="0 0 41 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mb-1"
            >
              <path
                d="M29.104 0H11.9164C4.45072 0 0 4.45072 0 11.9164V29.0835C0 36.5697 4.45072 41.0204 11.9164 41.0204H29.0835C36.5492 41.0204 40.9999 36.5697 40.9999 29.104V11.9164C41.0204 4.45072 36.5697 0 29.104 0ZM14.5828 30.5602C14.5828 31.1345 14.1315 31.5857 13.5573 31.5857H7.8349C7.26062 31.5857 6.80939 31.1345 6.80939 30.5602V21.0845C6.80939 19.7924 7.85541 18.7463 9.14756 18.7463H13.5573C14.1315 18.7463 14.5828 19.1976 14.5828 19.7719V30.5602ZM24.3867 30.5602C24.3867 31.1345 23.9354 31.5857 23.3611 31.5857H17.6388C17.0645 31.5857 16.6133 31.1345 16.6133 30.5602V11.7729C16.6133 10.4807 17.6593 9.4347 18.9514 9.4347H22.069C23.3611 9.4347 24.4072 10.4807 24.4072 11.7729V30.5602H24.3867ZM34.211 30.5602C34.211 31.1345 33.7598 31.5857 33.1855 31.5857H27.4632C26.8889 31.5857 26.4377 31.1345 26.4377 30.5602V23.2791C26.4377 22.7048 26.8889 22.2536 27.4632 22.2536H31.8729C33.165 22.2536 34.211 23.2996 34.211 24.5918V30.5602Z"
                fill="#FF9A45"
              />
            </svg>

            <div className="flex flex-col">
              <p className="text-sm text-custom-text-gray ">Play Counts</p>
              <p className="text-5xl font-bold">
                {globalState.contributorData.counts}
              </p>
            </div>
          </div>
          <div className="flex flex-row items-end space-x-4">
            <svg
              width="41"
              height="41"
              viewBox="0 0 41 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mb-1"
            >
              <path
                d="M16.3013 17.3486C16.3013 18.4156 16.5977 18.5736 17.2695 18.8107L18.2772 19.1664V15.8074H17.6844C16.9336 15.8074 16.3013 16.4989 16.3013 17.3486Z"
                fill="#FF9A45"
              />
              <path
                d="M21.241 26.6762H21.8337C22.6043 26.6762 23.2169 25.9846 23.2169 25.135C23.2169 24.068 22.9205 23.9099 22.2487 23.6728L21.241 23.3171V26.6762Z"
                fill="#FF9A45"
              />
              <path
                d="M34.7364 8.35808L30.6858 12.4087C30.3894 12.7051 30.014 12.8434 29.6386 12.8434C29.2631 12.8434 28.8877 12.7051 28.5913 12.4087C28.0183 11.8357 28.0183 10.8872 28.5913 10.3142L32.6419 6.26362C29.1643 3.29976 24.679 1.48193 19.759 1.48193C8.85205 1.48193 0 10.334 0 21.241C0 32.148 8.85205 41 19.759 41C30.666 41 39.5181 32.148 39.5181 21.241C39.5181 16.321 37.7002 11.8357 34.7364 8.35808ZM23.2169 20.8853C24.4814 21.3398 26.1807 22.2487 26.1807 25.1533C26.1807 27.6231 24.2246 29.6583 21.8337 29.6583H21.241V30.1523C21.241 30.9624 20.5692 31.6342 19.759 31.6342C18.9489 31.6342 18.2771 30.9624 18.2771 30.1523V29.6583H18.119C15.4911 29.6583 13.3373 27.4453 13.3373 24.7186C13.3373 23.8887 14.0092 23.2169 14.8193 23.2169C15.6294 23.2169 16.3012 23.8887 16.3012 24.6988C16.3012 25.7855 17.1113 26.6747 18.119 26.6747H18.2771V22.2882L16.3012 21.5966C15.0366 21.1422 13.3373 20.2333 13.3373 17.3287C13.3373 14.8588 15.2935 12.8236 17.6843 12.8236H18.2771V12.3494C18.2771 11.5393 18.9489 10.8675 19.759 10.8675C20.5692 10.8675 21.241 11.5393 21.241 12.3494V12.8434H21.399C24.027 12.8434 26.1807 15.0564 26.1807 17.7831C26.1807 18.5933 25.5089 19.2651 24.6988 19.2651C23.8887 19.2651 23.2169 18.5933 23.2169 17.7831C23.2169 16.6964 22.4067 15.8072 21.399 15.8072H21.241V20.1937L23.2169 20.8853Z"
                fill="#FF9A45"
              />
              <path
                d="M40.8815 0.908916C40.7234 0.553253 40.4468 0.256867 40.0714 0.0987951C39.8935 0.039518 39.7157 0 39.5181 0H31.6145C30.8044 0 30.1326 0.671807 30.1326 1.48193C30.1326 2.29205 30.8044 2.96386 31.6145 2.96386H35.9417L32.642 6.26361C33.3928 6.91566 34.0844 7.60723 34.7364 8.35807L38.0362 5.05831V9.38554C38.0362 10.1957 38.708 10.8675 39.5181 10.8675C40.3282 10.8675 41 10.1957 41 9.38554V1.48193C41 1.28434 40.9605 1.10651 40.8815 0.908916Z"
                fill="#FF9A45"
              />
            </svg>

            <div className="flex flex-col">
              <p className="text-sm text-custom-text-gray ">Royalty</p>
              <p className="text-5xl font-bold">{totalRoyaltyEarned}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col p-4 px-6 mt-6 bg-custom-white rounded-3xl md:w-full md:p-8 lg:w-full lg:p-8">
        <div className="flex flex-row items-center justify-between w-full my-3">
          <p className="font-bold text-md">Your Contributions</p>
        </div>
        <div className="flex flex-col items-center w-full mt-2 space-y-6">
          {songCards}
        </div>
      </div>
    </>
  );
}

export default ContributorDashboard;
