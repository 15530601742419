import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../AppContext";

import songImage from "../assets/song.png";
import playIcon from "../assets/icons/playone.svg";
import likedIcon from "../assets/icons/liked.svg";
import likableIcon from "../assets/icons/likable.svg";
import useWindowSize from "../utils/useWindowSize";

function SongCard(props) {
  const { globalState, updateUserObject } = useContext(AppContext);
  const { isMdScreen, isLgScreen } = useWindowSize();
  const [getLike, setGetLike] = useState("db");
  const [localLike, setLocalLike] = useState(props.isLiked);

  const formatPlayCount = (value) => {
    value = parseInt(value);
    if (value < 1000) {
      return value.toString();
    } else if (value < 10000) {
      return (value / 1000).toFixed(2) + "k";
    } else {
      return Math.floor(value / 1000).toString() + "k";
    }
  };

  return (
    <div className="flex flex-row lg:flex-row md:flex-col  justify-between p-2 w-full items-center rounded-2xl px-4 ">
      <div className="flex flex-row items-center lg:space-between space-x-4 md:space-x-4 md:w-full">
        <div className="flex flex-col w-14 h-14">
          <div
            className="w-12 h-12 md:w-12 md:h-12 lg:w-14 lg:h-14 rounded-lg overflow-hidden"
            style={{ aspectRatio: "1/1" }}
          >
            <img
              src={props.songCover}
              className="w-full h-full object-cover"
              alt="Song Image"
            />
          </div>
        </div>

        {/* <div className="flex flex-col w-12 h-12">
          <img
            src={props.songCover}
            className="w-12 h-12 md:w-12 md:h-12 lg:w-14 lg:h-14 rounded-lg object-cover"
            alt="Song Image"
            style={{ objectFit: "cover" }}
          />
        </div> */}

        <div className="flex flex-col items-left overflow-hidden w-36">
          {isLgScreen === true ? (
            <p
              className={
                props.songName.length > 15
                  ? "animate-marquee whitespace-nowrap"
                  : "whitespace-nowrap"
              }
            >
              {props.songName}1
            </p>
          ) : isMdScreen === true ? (
            <p
              className={
                props.songName.length > 7
                  ? " animate-marquee whitespace-nowrap"
                  : " whitespace-nowrap"
              }
            >
              {props.songName}2
            </p>
          ) : (
            <p
              className={
                props.songName.length > 5
                  ? "animate-marquee whitespace-nowrap"
                  : "whitespace-nowrap"
              }
            >
              {props.songName}3
            </p>
          )}
          <p className="text-sm text-custom-text-gray md:text-xs capitalize">
            {props.albumName}
          </p>
        </div>
      </div>
      <div className="flex flex-row items-center lg:justify-end space-between space-x-4 md:w-full md:justify-between md:mt-6 lg:mt-0 md:px-2">
        <div
          className={
            props.showPlayCount === true
              ? "flex flex-row items-center bg-custom-text-gray/30 p-2 rounded-lg space-x-2 hover:bg-custom-text-gray/40 hover:cursor-pointer"
              : "flex flex-row items-center  p-2 rounded-lg space-x-2 hover:cursor-pointer"
          }
        >
          <svg
            width="10"
            height="13"
            viewBox="0 0 10 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.65909 7.57169C9.61648 7.63001 9.41761 7.87789 9.26136 8.03828L9.17614 8.12576C7.98295 9.45261 5.0142 11.4502 3.50852 12.0917C3.50852 12.1063 2.61364 12.4854 2.1875 12.5H2.13068C1.47727 12.5 0.866477 12.1209 0.553977 11.5085C0.383523 11.1731 0.227273 10.1962 0.213068 10.1817C0.0852273 9.3068 0 7.96683 0 6.49271C0 4.94714 0.0852273 3.54739 0.241477 2.68712C0.241477 2.67254 0.397727 1.88518 0.497159 1.62272C0.653409 1.24362 0.9375 0.922843 1.29261 0.718712C1.5767 0.572904 1.875 0.5 2.1875 0.5C2.5142 0.514581 3.125 0.734751 3.36648 0.835358C4.95739 1.47691 7.99716 3.57655 9.16193 4.85966C9.3608 5.06379 9.57386 5.31167 9.63068 5.36999C9.87216 5.69077 10 6.08445 10 6.50875C10 6.88639 9.88636 7.26549 9.65909 7.57169Z"
              fill={props.showPlayCount === true ? "#FF9A45" : "#FF9A45"}
              fill-opacity="0.72"
            />
          </svg>

          {props.showPlayCount === true ? (
            <p className="text-xs text-black w-4 text-center">
              {/* {isNaN(formatPlayCount(props.playCount)) === true
                ? 0
                : formatPlayCount(props.playCount)} */}
              {props.playCount}
            </p>
          ) : null}
        </div>
        {props.showLikeButton === true ? (
          <img
            src={
              getLike === "db"
                ? props.isLiked === true
                  ? likedIcon
                  : likableIcon
                : localLike === true
                ? likedIcon
                : likableIcon
            }
            className="w-4 h-4 md:w-4 md:h-4 lg:w-4 lg:h-4 hover:cursor-pointer"
            alt="Like Icon"
            onClick={() => {
              console.log(
                "Like Icon Clicked",
                globalState.userObject,
                props.songId
              );
              setGetLike("local");
              setLocalLike(!localLike);
              if (localLike === false) {
                console.log("Liked");
                let tempUserObject = globalState.userObject;
                tempUserObject.likedSongs.push(props.songId);
                updateUserObject(tempUserObject, true);
              } else {
                console.log("Unliked");
                let tempUserObject = globalState.userObject;
                tempUserObject.likedSongs = tempUserObject.likedSongs.filter(
                  (song) => song !== props.songId
                );
                updateUserObject(tempUserObject, true);
              }
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

export default SongCard;
