import React, { useState } from "react";

function SearchBar() {
  const [query, setQuery] = useState("");

  const handleChange = (event) => {
    setQuery(event.target.value);
    // Perform search action with `query`
  };

  return (
    <div className="relative rounded-full bg-white md:w-fit  lg:w-[400px]">
      <input
        type="text"
        value={query}
        onChange={handleChange}
        placeholder="Search for a song"
        className="rounded-full py-2 pl-12 pr-4 text-sm text-gray-900 focus:outline-none focus:shadow-outline italic w-full"
      />
      <div className="absolute inset-y-0 left-0 flex items-center pl-4">
        <svg
          width="20"
          height="20"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34.5217 8.28521H23.4746C22.7198 8.28521 22.0938 7.65921 22.0938 6.90432C22.0938 6.14944 22.7198 5.52344 23.4746 5.52344H34.5217C35.2766 5.52344 35.9026 6.14944 35.9026 6.90432C35.9026 7.65921 35.2766 8.28521 34.5217 8.28521Z"
            fill="#7C8DB5"
            fill-opacity="0.72"
          />
          <path
            d="M28.9982 13.8086H23.4746C22.7198 13.8086 22.0938 13.1826 22.0938 12.4278C22.0938 11.6729 22.7198 11.0469 23.4746 11.0469H28.9982C29.7531 11.0469 30.3791 11.6729 30.3791 12.4278C30.3791 13.1826 29.7531 13.8086 28.9982 13.8086Z"
            fill="#7C8DB5"
            fill-opacity="0.72"
          />
          <path
            d="M18.8721 37.7442C8.46943 37.7442 0 29.2748 0 18.8721C0 8.46943 8.46943 0 18.8721 0C19.627 0 20.253 0.626001 20.253 1.38088C20.253 2.13577 19.627 2.76177 18.8721 2.76177C9.97919 2.76177 2.76177 9.9976 2.76177 18.8721C2.76177 27.7466 9.97919 34.9824 18.8721 34.9824C27.765 34.9824 34.9824 27.7466 34.9824 18.8721C34.9824 18.1172 35.6084 17.4912 36.3633 17.4912C37.1182 17.4912 37.7442 18.1172 37.7442 18.8721C37.7442 29.2748 29.2748 37.7442 18.8721 37.7442Z"
            fill="#7C8DB5"
            fill-opacity="0.72"
          />
          <path
            d="M38.2051 39.5841C37.8553 39.5841 37.5055 39.4552 37.2293 39.179L33.5469 35.4966C33.013 34.9627 33.013 34.0789 33.5469 33.545C34.0809 33.011 34.9646 33.011 35.4986 33.545L39.1809 37.2273C39.7149 37.7613 39.7149 38.6451 39.1809 39.179C38.9048 39.4552 38.555 39.5841 38.2051 39.5841Z"
            fill="#7C8DB5"
            fill-opacity="0.72"
          />
        </svg>
      </div>
    </div>
  );
}

export default SearchBar;
