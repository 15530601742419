import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";

import logo from "../assets/logo.png";
import Tag from "../components/Tag";
import sidebarIcon from "../assets/icons/sidebar.svg";
import ListenerMenuOptions from "../components/ListenerMenuOptions";
import AuthorMenuOptions from "../components/AuthorMenuOptions";
import Player from "../components/Player";
import AddressDisplay from "../components/AddressDisplay";
import ContributorMenuOptions from "../components/ContributorMenuOptions";

function Navbar(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { globalState, setGlobalState, tools } = useContext(AppContext);

  function toggleSidebar() {
    setIsOpen(!isOpen);
  }
  return (
    <>
      <button
        data-drawer-target="logo-sidebar"
        data-drawer-toggle="logo-sidebar"
        aria-controls="logo-sidebar"
        aria-expanded={isOpen ? "true" : "false"}
        type="button"
        class="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 pt-4 pb-6 space-x-4"
        onClick={toggleSidebar}
      >
        <img src={sidebarIcon} className="w-8 h-8"></img>
        <p class="text-4xl font-bold">ROP</p>
      </button>

      <aside
        id="logo-sidebar"
        class={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${
          isOpen ? "translate-x-0" : "-translate-x-full sm:translate-x-0"
        }`}
        aria-label="Sidebar"
      >
        <div class="h-full px-3 py-4 overflow-y-auto bg-white">
          <div className="flex flex-col items-center">
            <img src={logo} className="w-40 h-32 my-4"></img>
            <p class="text-2xl text-center font-bold">ROP PLATFORM</p>
            <div className="flex flex-col items-center my-6 space-y-3">
              <p className="text-lg font-bold text-center capitalize">
                Welcome, {globalState.userObject.name.trim(0, 12)}
              </p>
              {globalState.userObject.walletAddress !== "" ? (
                <AddressDisplay
                  address={
                    globalState.userObject.walletAddress.slice(0, 8) +
                    "..." +
                    globalState.userObject.walletAddress.slice(-8)
                  }
                />
              ) : null}
              <Tag
                label={globalState.userObject.role}
                songCountDisplay={false}
              ></Tag>
            </div>
          </div>
          <div className="pl-2 mt-4 mb-3">
            <p className="text-custom-text-gray">MENU</p>
          </div>
          <ul class="space-y-2 font-medium">
            {globalState.isAuthenticated === true ? (
              globalState.userObject.role === "author" ? (
                <AuthorMenuOptions />
              ) : globalState.userObject.role === "contributor" ? (
                <ContributorMenuOptions />
              ) : (
                <ListenerMenuOptions />
              )
            ) : null}

            {isOpen === true ? (
              <div className="w-full">
                <button
                  className="w-full mt-8 capitalize border-0 btn bg-custom-primary rounded-2xl"
                  onClick={toggleSidebar}
                >
                  Close
                </button>
              </div>
            ) : null}
          </ul>
        </div>
      </aside>

      <div class="pt-4 px-4 sm:ml-64 bg-custom-bg-gray">
        <div class="p-4 border-0 border-gray-200 border-dashed rounded-lg dark:border-gray-700 pb-32">
          {props.page}
        </div>
        <div class="fixed bottom-0 lg:left-[280px] lg:right-[30px] md:right-[30px] md:left-[280px] ">
          <Player />
        </div>
      </div>
    </>
  );
}

export default Navbar;
