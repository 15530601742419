import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../AppContext";

import SongCard from "./SongCard";

function TopSongs(props) {
  const { globalState, imageConverter } = useContext(AppContext);
  const [songCards, setSongCards] = useState([]);
  const navigate = useNavigate();

  const processSongCards = () => {
    console.log(
      "Processing Song Cards",
      globalState.onChainSongData,
      globalState.allAlbums
    );
    let tempArr = [];
    if (globalState.allAlbums.length > 0) {
      globalState.allAlbums.forEach((album) => {
        console.log("...album found");
        album.songs.forEach((song) => {
          console.log(
            "...album-song found",
            song.songId,
            globalState.onChainSongData
          );
          const {
            songId,
            songName,
            songCover,
            albumName,
            albumId,
            timestamp,
            playCounts,
          } = song;
          console.log("...album-song-defined", songCards);
          const card = (
            <SongCard
              key={songId}
              songId={songId}
              songName={songName}
              songCover={imageConverter(songCover)}
              albumName={albumName}
              albumId={albumId}
              timestamp={timestamp}
              playCount={playCounts}
              showPlayCount={true}
              showLikeButton={true}
              isLiked={
                globalState.userObject.likedSongs.includes(songId)
                  ? true
                  : false
              }
            />
          );
          tempArr.push(card);
          tempArr.sort((a, b) => b.props.playCount - a.props.playCount);
          setSongCards(tempArr);
          console.log("Song Cards", tempArr);
        });
      });
    }
  };

  useEffect(() => {
    console.log("Processing Top Songs");
    processSongCards();
  }, [globalState.allAlbums]);

  return (
    <div className="flex flex-col justify-between items-center p-4 px-6 w-full bg-custom-white rounded-3xl mt-6">
      <div className="flex flex-row justify-between w-full items-center my-3">
        <p className="text-md font-bold">Top Songs</p>
        <p
          className="text-custom-text-gray text-sm hover:cursor-pointer"
          onClick={() => navigate("/explore-songs")}
        >
          See All
        </p>
      </div>
      <div className="flex flex-col items-center space-y-3 w-full mt-2">
        {songCards.length > 0 ? (
          songCards
        ) : (
          <p className="text-md font-bold mb-4">No Songs</p>
        )}
      </div>
    </div>
  );
}

export default TopSongs;
