import React from "react";
import { useParams } from "react-router-dom";
import Titlebar from "../../components/Titlebar";

import albumcover from "../../assets/albumcover_sample.png";
import artistProfileAlbum from "../../assets/icons/artistProfileAlbum.svg";
import artistProfileSongs from "../../assets/icons/artistProfileSongs.svg";
import verified from "../../assets/icons/verified.svg";
import Tag from "../../components/Tag";
import ArtistCard from "../../components/ArtistCard";
import DetailedSongCard from "../../components/DetailedSongCard";

function AlbumView(props) {
  const { albumName } = useParams();
  return (
    <>
      <Titlebar 
        showIcon={true}
        showSearchBar={false}
      />
      <div className="flex flex-col lg:justify-between items-center p-4 px-6 bg-custom-white rounded-3xl mt-6 md:w-full md:p-8 lg:w-full lg:p-8">
        <div className="flex flex-row justify-start w-full items-center lg:items-start md:items-start ">
          <img
            src={albumcover}
            alt="albumcover"
            className="w-20 h-20 lg:w-36 lg:h-36 md:w-28 md:h-28  rounded-2xl"
          />
          <div className="flex flex-col justify-start ml-4 w-full lg:space-y-4 md:space-y-3 space-y-2">
            <div className="flex flex-row  w-full space-x-4">
              <p className="md:text-4xl  lg:text-5xl font-extrabold">
                Nights
              </p>
            </div>
            <Tag label="13,456,887 Play Counts" songCountDisplay={true} />
            <div className="flex flex-row justify-left space-x-8">
              <div className="flex flex-row justify-center space-x-2 items-center">
                <img
                  src={artistProfileSongs}
                  alt="artistProfileSong"
                  className="lg:w-6 md:h-6 w-4 h-4"
                />
                <p className="text-custom-text-gray lg:text-sm md:text-sm text-xs">
                  124 Songs
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:justify-between items-center p-4 px-6 bg-custom-white rounded-3xl mt-6 md:w-full md:p-8 lg:w-full lg:p-8 ">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <p className="text-md font-bold">Songs</p>
        </div>
        <div className="flex flex-col w-full">
            <DetailedSongCard 
                songName="Shape of You"
                showPlayCount={true}
            />
            <DetailedSongCard 
                songName="Shape of You"
                showPlayCount={true}
            />
        </div>
      </div>
    </>
  );
}

export default AlbumView;
