import React, {useContext} from "react";
import { AppContext } from "../AppContext";

import LoadingSVG from "../assets/loading.svg";

function Loading(props) {
  const { globalState, setGlobalState } = useContext(AppContext);

  return (
    <div className="modal modal-open flex flex-col items-center h-full w-full bg-white/90">
      <img src={LoadingSVG} alt="Loading" className="h-40 w-40" />
      <p className="text-xl font-bold">{globalState.loading.message}</p>
      {globalState.loading.error === true ? (
        <button
          className="bg-custom-primary px-4 py-2  text-white mt-8"
          onClick={() =>
            setGlobalState((prevState) => ({
              ...prevState,
              loading: {
                status: false,
                message: "",
                error: false,
              },
            }))
          }
        >
          Close
        </button>
      ) : null}
    </div>
  );
}

export default Loading;
