import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { useNavigate } from "react-router-dom";
import { Jazzicon } from "@ukstv/jazzicon-react";
import { toast } from "react-hot-toast";
import axios from "axios";

import Titlebar from "../../components/Titlebar";
import InputOne from "../../components/InputOne";
import ButtonOne from "../../components/ButtonOne";

import uploadIcon from "../../assets/icons/uploadIcon.svg";

function AddSong(props) {
  const {
    globalState,
    setGlobalState,
    checkJWTStatus,
    updateUserObject,
    imageConverter,
    createAlbum,
    getAlbumsOfAuthor,
    addNewSong,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [songName, setSongName] = useState("");
  const [song, setSong] = useState(null);
  const [albumOptions, setAlbumOptions] = useState([]);
  const [selectedAlbum, setSelectedAlbum] = useState("");
  const [albumName, setAlbumName] = useState("");

  const handleImageChange = (e) => {
    console.log("Image Change: ", e.target.files);
    const file = e.target.files[0];
    setImage(file);
  };

  const handleSong = async (e) => {
    let file = e.target.files[0];
    setSong(file);
  };

  const initAuthCheck = async () => {
    console.log("[+] Checking if user is authenticated", globalState);
    if (globalState.isAuthenticated === false) {
      console.log("[+] User is not authenticated");
      const status = await checkJWTStatus("Author Dashoboard");
      if (status === false) {
        navigate("/login");
      } else {
        getAlbumsOfAuthor(globalState.userObject.id);
      }
    } else {
      getAlbumsOfAuthor(globalState.userObject.id);
    }
  };

  const getAlbumOptions = () => {
    let options = globalState.albums.map((album) => ({
      value: album.albumId,
      label: album.albumName,
    }));
    console.log("Album Options: ", options);
    setAlbumOptions(options);
  };

  //   ================================================================

  const [contributors, setContributors] = useState([
    {
      walletAddress: globalState.userObject.walletAddress,
      percentageShare: "",
      removable: false,
    },
    { walletAddress: "", percentageShare: "", removable: true },
  ]);

  const handleWalletAddressChange = (index, value) => {
    const updatedContributors = [...contributors];
    updatedContributors[index].walletAddress = value;
    setContributors(updatedContributors);
  };

  const handlePercentageShareChange = (index, value) => {
    const updatedContributors = [...contributors];
    updatedContributors[index].percentageShare = value;
    setContributors(updatedContributors);
  };

  const handleAddContributor = () => {
    setContributors([
      ...contributors,
      { walletAddress: "", percentageShare: "", removable: true },
    ]);
  };

  const handleRemoveContributor = (index) => {
    if (contributors[index].removable) {
      const updatedContributors = [...contributors];
      updatedContributors.splice(index, 1);
      setContributors(updatedContributors);
    }
  };

  const handleContributors = () => {
    const totalShare = contributors.reduce(
      (sum, contributor) => sum + Number(contributor.percentageShare),
      0
    );

    if (contributors[0].percentageShare === "") {
      toast.error('Please enter a share value for "(You)" contributor.');
      return false;
    } else if (totalShare > 100) {
      toast.error("The sum of share values cannot exceed 100%.");
      return false;
    } else if (
      contributors.some((contributor) => contributor.percentageShare === "")
    ) {
      toast.error("Please enter share values for all contributors.");
      return false;
    } else if (totalShare < 100) {
      toast.error("The sum of share values cannot be less than 100%.");
      return false;
    } else if (
      contributors.some((contributor) => contributor.walletAddress === "")
    ) {
      toast.error("Please enter wallet addresses for all contributors.");
      return false;
    } else {
      // Submit the form
      console.log("Form submitted:", contributors);
      return true;
    }
  };

  const handleAlbumChange = (event, selectedLabel) => {
    console.log("Album Change: ", event.target.value, selectedLabel);
    setSelectedAlbum(event.target.value);
    setAlbumName(selectedLabel);
  };

  //   ================================================================

  useEffect(() => {
    initAuthCheck();
  }, []);

  useEffect(() => {
    if (globalState.albums.length > 0) getAlbumOptions();
  }, [globalState.albums]);

  return (
    <>
      <Titlebar
        showIcon={false}
        showSearchBar={false}
        pageTitle={"Upload New Song"}
      />

      {globalState.userObject.walletAddress === "" ? (
        <div className="flex flex-col items-center text-2xl font-bold  text-black/40 mt-16">
          Please connect your wallet in Profile section to upload a song
        </div>
      ) : globalState.albums.length > 0 ? (
        <div className="flex flex-col p-8 rounded-3xl bg-custom-white mt-6">
          <p className="text-md font-bold mb-6">Song Metadata</p>
          <div className="w-fit">
            <InputOne
              label="Song Name"
              placeholder="Song Name"
              onInput={(e) => {
                console.log(e.target.value);
                setSongName(e.target.value);
              }}
              value={songName}
            />

            <div className="flex flex-row space-x-4">
              <div
                className={
                  image !== null
                    ? "relative flex flex-col justify-center rounded-3xl bg-dark-green w-fit py-12 mt-4"
                    : "relative flex flex-col justify-center rounded-3xl bg-custom-text-gray w-fit py-12 mt-4"
                }
              >
                <input
                  type="file"
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                  onInput={(e) => {
                    console.log("image changed");
                    handleImageChange(e);
                  }}
                />
                <img
                  src={uploadIcon}
                  alt="uploadIcon"
                  className="w-12 h-12 mx-auto"
                />
                <p className="text-sm font-bold text-custom-white p-2 hover:cursor-pointer w-44 text-center">
                  {image !== null ? "Uploaded" : "Upload Song Cover"}
                </p>
              </div>
              <div
                className={
                  song !== null
                    ? "relative flex flex-col justify-center rounded-3xl bg-dark-green w-fit py-12 mt-4"
                    : "relative flex flex-col justify-center rounded-3xl bg-custom-text-gray w-fit py-12 mt-4"
                }
              >
                <input
                  type="file"
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                  accept="audio/mpeg"
                  onInput={(e) => {
                    console.log("song changed");
                    handleSong(e);
                  }}
                />
                <img
                  src={uploadIcon}
                  alt="uploadIcon"
                  className="w-12 h-12 mx-auto"
                />
                <p className="text-sm font-bold text-custom-white p-2 hover:cursor-pointer w-44 text-center">
                  {song !== null ? "Uploaded" : "Upload Song"}
                </p>
              </div>
            </div>
          </div>
          <p className="text-md font-bold mt-12">Add Contributors</p>

          {/* ================================================ */}

          <div>
            {contributors.map((contributor, index) => (
              <div
                key={index}
                className="flex flex-row w-fit mt-4 mb-4 space-x-2"
              >
                <InputOne
                  type="text"
                  placeholder="Wallet Address"
                  value={contributor.walletAddress}
                  disabled={!contributor.removable}
                  onInput={(e) =>
                    handleWalletAddressChange(index, e.target.value)
                  }
                />
                <InputOne
                  type="number"
                  placeholder="Percentage Share"
                  value={contributor.percentageShare}
                  onInput={(e) =>
                    handlePercentageShareChange(index, e.target.value)
                  }
                />
                {contributor.removable && (
                  <ButtonOne
                    onClick={() => handleRemoveContributor(index)}
                    label="-"
                  ></ButtonOne>
                )}
              </div>
            ))}
            <div className="flex flex-row space-x-2">
              <ButtonOne
                onClick={handleAddContributor}
                label="Add Contributor"
              ></ButtonOne>
            </div>
          </div>

          {/* ================================================ */}

          <div className="flex flex-row space-x-4 mt-4 items-center">
            <label htmlFor="albumSelect">Choose Album:</label>
            <select
              id="albumSelect"
              className=" bg-white border border-gray-300 rounded px-2  py-2"
              value={selectedAlbum}
              // onChange={handleAlbumChange}
              onChange={(event) =>
                handleAlbumChange(event, event.target.selectedOptions[0].label)
              }
            >
              <option value="">Select Album</option>
              {albumOptions.map((album) => (
                <option key={album.value} value={album.value}>
                  {album.label}
                </option>
              ))}
            </select>
          </div>

          <div className="mt-8">
            <ButtonOne
              label="Upload Song"
              className=""
              onClick={async () => {
                console.log("Upload Song Clicked");
                const contributorsCheck = handleContributors();
                if (
                  contributorsCheck === true &&
                  image !== null &&
                  songName !== "" &&
                  songName !== null &&
                  selectedAlbum !== ""
                ) {
                  addNewSong(
                    songName,
                    song,
                    image,
                    contributors,
                    selectedAlbum,
                    albumName
                  );
                  setImage(null);
                  setSong(null);
                  setImageName("");
                  setSongName("");
                  setSelectedAlbum("");
                } else {
                  toast.error("Please fill all the fields");
                  console.log("selected album", selectedAlbum);
                }
              }}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center text-2xl font-bold  text-black/40 mt-16">
          Please create a album to upload song
        </div>
      )}
    </>
  );
}

export default AddSong;
