import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";

import playlistIcon from "../assets/icons/playlist.svg";
import logoutIcon from "../assets/icons/logout.svg";
import albumsIcon from "../assets/icons/albums.svg";
import homeIcon from "../assets/icons/home.svg";
import profileIcon from "../assets/icons/Profile.svg";
import settingsIcon from "../assets/icons/Setting.svg";

function AuthorMenuOptions(props) {
  const { globalState, logout } = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <>
      <li>
        <a
          // href="/dashboard"
          className={
            window.location.pathname === "/dashboard"
              ? "flex items-center p-2 text-custom-primary rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:cursor-pointer"
              : "flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:cursor-pointer"
          }
          onClick={() => navigate("/dashboard")}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.3586 6.67534L11.9003 2.30867C10.8336 1.45867 9.16696 1.45034 8.10863 2.30034L2.65029 6.67534C1.86696 7.30034 1.39196 8.55034 1.55863 9.53367L2.60863 15.817C2.85029 17.2253 4.15863 18.3337 5.58363 18.3337H14.417C15.8253 18.3337 17.1586 17.2003 17.4003 15.8087L18.4503 9.52534C18.6003 8.55034 18.1253 7.30034 17.3586 6.67534ZM10.6253 15.0003C10.6253 15.342 10.342 15.6253 10.0003 15.6253C9.65863 15.6253 9.37529 15.342 9.37529 15.0003V12.5003C9.37529 12.1587 9.65863 11.8753 10.0003 11.8753C10.342 11.8753 10.6253 12.1587 10.6253 12.5003V15.0003Z"
              fill={
                window.location.pathname === "/dashboard"
                  ? "#FF9A45"
                  : "#7C8DB5"
              }
              fillOpacity={
                window.location.pathname === "/dashboard" ? "1" : "0.72"
              }
            />
            <defs>
              <linearGradient
                id="paint0_linear_515_305"
                x1="10.0016"
                y1="-6.66635"
                x2="-6.66263"
                y2="10.2837"
                gradientUnits="userSpaceOnUse"
              ></linearGradient>
            </defs>
          </svg>

          <span class="flex-1 ml-3 whitespace-nowrap">Home</span>
        </a>
      </li>
      <li>
        <a
          // href="/liked-songs"
          className={
            window.location.pathname === "/add-song"
              ? "flex items-center p-2 text-custom-primary rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:cursor-pointer"
              : "flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:cursor-pointer"
          }
          onClick={() => navigate("/add-song")}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M16.542 13.7202C16.215 14.7987 15.5696 15.6297 14.6502 16.1425C14.6148 16.001 14.5972 15.8507 14.5972 15.7005C14.5972 14.6219 15.4724 13.7379 16.542 13.7202Z"
              fill={
                window.location.pathname === "/add-song" ? "#FF9A45" : "#7C8DB5"
              }
              fill-opacity={
                window.location.pathname === "/add-song" ? "1" : "0.72"
              }
            />
            <path
              d="M16.7967 4.87988V6.6161C16.7967 7.07803 16.4307 7.45189 15.9834 7.56735L13.3755 8.29226C11.9964 8.64587 11.077 9.83932 11.077 11.2626V12.6284C11.077 13.0479 10.774 13.3981 10.3973 13.5827C9.49959 14.0225 8.81366 14.9149 8.55482 15.9221C8.43326 16.395 8.04676 16.7967 7.55851 16.7967H4.87988C1.82995 16.7967 0 14.9756 0 11.9168V4.87988C0 1.82111 1.82995 0 4.87988 0H11.9168C14.9667 0 16.7967 1.82111 16.7967 4.87988Z"
              fill={
                window.location.pathname === "/add-song" ? "#FF9A45" : "#7C8DB5"
              }
              fill-opacity={
                window.location.pathname === "/add-song" ? "1" : "0.72"
              }
            />
            <path
              d="M17.9538 9.06255C17.724 8.89458 17.3527 8.71777 16.7957 8.77082C16.6543 8.77966 16.504 8.80618 16.336 8.85038L13.7016 9.57529C12.9148 9.77862 12.4021 10.4416 12.4021 11.2638V14.7204C12.1987 14.6497 11.9777 14.6055 11.7479 14.6055C10.6517 14.6055 9.75879 15.4895 9.75879 16.5857C9.75879 16.6564 9.75879 16.7271 9.76763 16.7979C9.87371 17.788 10.7224 18.5659 11.7479 18.5659C12.8264 18.5659 13.7016 17.6907 13.7193 16.6211C13.7193 16.6034 13.7281 16.5945 13.7281 16.5857V13.3236L16.778 12.4838L17.2377 12.36V13.8452C17.0344 13.7745 16.8134 13.7214 16.5747 13.7214H16.5393C15.4697 13.7391 14.5945 14.6231 14.5945 15.7017C14.5945 15.8519 14.6121 16.0022 14.6475 16.1437C14.8508 17.0277 15.6376 17.6907 16.5747 17.6907C17.6621 17.6907 18.5373 16.8155 18.5549 15.737C18.5549 15.7282 18.5638 15.7193 18.5638 15.7017V10.5477C18.5638 9.87586 18.3605 9.3808 17.9538 9.06255ZM11.7479 17.2399C11.4561 17.2399 11.2086 17.0542 11.129 16.7979C11.0937 16.736 11.0848 16.6564 11.0848 16.5857C11.0848 16.2232 11.3854 15.9315 11.7479 15.9315C12.1015 15.9315 12.4021 16.2232 12.4021 16.5857C12.4021 16.6564 12.3932 16.7183 12.3667 16.7802C12.2871 17.0454 12.0396 17.2399 11.7479 17.2399ZM16.5747 16.3647C16.2211 16.3647 15.9205 16.0641 15.9205 15.7017C15.9205 15.348 16.2211 15.0475 16.5747 15.0475C16.9372 15.0475 17.2377 15.348 17.2377 15.7017C17.2377 16.0641 16.9372 16.3647 16.5747 16.3647Z"
              fill={
                window.location.pathname === "/add-song" ? "#FF9A45" : "#7C8DB5"
              }
              fill-opacity={
                window.location.pathname === "/add-song" ? "1" : "0.72"
              }
            />
          </svg>

          <span class="flex-1 ml-3 whitespace-nowrap">Upload New Song</span>
          {/* <span class="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300">
                  Pro
                </span> */}
        </a>
      </li>
      <li>
        <a
          // href="/create-album"
          className={
            window.location.pathname === "/create-album"
              ? "flex items-center p-2 text-custom-primary rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:cursor-pointer"
              : "flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:cursor-pointer"
          }
          onClick={() => navigate("/create-album")}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4915 1.66699H6.51647C3.48314 1.66699 1.6748 3.47532 1.6748 6.50866V13.4837C1.6748 16.517 3.48314 18.3253 6.51647 18.3253H13.4915C16.5248 18.3253 18.3331 16.517 18.3331 13.4837V6.50866C18.3331 3.47532 16.5248 1.66699 13.4915 1.66699Z"
              fill={
                window.location.pathname === "/create-album"
                  ? "#FF9A45"
                  : "#7C8DB5"
              }
              fill-opacity={
                window.location.pathname === "/create-album" ? "1" : "0.72"
              }
            />
            <path
              d="M13.0163 5.91655L11.108 5.28326C10.6246 5.11659 10.133 5.18322 9.75796 5.44989C9.38296 5.71655 9.17462 6.16658 9.17462 6.67491V7.18323V10.6666C8.84129 10.4832 8.46627 10.3749 8.05793 10.3749C6.7746 10.3749 5.72461 11.4249 5.72461 12.7082C5.72461 13.9916 6.7746 15.0416 8.05793 15.0416C9.34127 15.0416 10.3913 13.9916 10.3913 12.7082V8.91658C10.3996 8.92492 10.4163 8.92488 10.4246 8.93321L12.3329 9.56656C12.5079 9.62489 12.6913 9.65826 12.8663 9.65826C13.1663 9.65826 13.4496 9.57493 13.6829 9.39993C14.0579 9.13326 14.2663 8.68324 14.2663 8.1749V7.66658C14.2663 6.90825 13.7329 6.15822 13.0163 5.91655ZM8.05793 13.8249C7.44127 13.8249 6.94964 13.3249 6.94964 12.7166C6.94964 12.0999 7.4496 11.5999 8.05793 11.5999C8.6746 11.5999 9.17462 12.0999 9.17462 12.7166C9.17462 13.3249 8.6746 13.8249 8.05793 13.8249Z"
              fill="white"
            />
          </svg>

          <span class="flex-1 ml-3 whitespace-nowrap">Create New Album</span>
          {/* <span class="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                  3
                </span> */}
        </a>
      </li>
      <li>
        <a
          // href="/my-albums"
          className={
            window.location.pathname === "/my-albums" ||
            window.location.pathname === "/view-album"
              ? "flex items-center p-2 text-custom-primary rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:cursor-pointer"
              : "flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:cursor-pointer"
          }
          onClick={() => navigate("/my-albums")}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 711 738"
            fill={
              window.location.pathname === "/my-albums" ||
              window.location.pathname === "/view-album"
                ? "#FF9A45"
                : "#7C8DB5"
            }
            fill-opacity={
              window.location.pathname === "/my-albums" ||
              window.location.pathname === "/view-album"
                ? "1"
                : "0.72"
            }
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M568.621 142.155H142.155C127.584 142.155 115.501 130.072 115.501 115.501C115.501 100.93 127.584 88.8472 142.155 88.8472H568.621C583.192 88.8472 595.275 100.93 595.275 115.501C595.275 130.072 583.192 142.155 568.621 142.155Z"
              fill={
                window.location.pathname === "/my-albums" ||
                window.location.pathname === "/view-album"
                  ? "#FF9A45"
                  : "#7C8DB5"
              }
              fill-opacity={
                window.location.pathname === "/my-albums" ||
                window.location.pathname === "/view-album"
                  ? "1"
                  : "0.72"
              }
            />
            <path
              d="M462.005 53.3083H248.772C234.201 53.3083 222.118 41.2251 222.118 26.6541C222.118 12.0832 234.201 0 248.772 0H462.005C476.576 0 488.659 12.0832 488.659 26.6541C488.659 41.2251 476.576 53.3083 462.005 53.3083Z"
              fill={
                window.location.pathname === "/my-albums" ||
                window.location.pathname === "/view-album"
                  ? "#FF9A45"
                  : "#7C8DB5"
              }
              fill-opacity={
                window.location.pathname === "/my-albums" ||
                window.location.pathname === "/view-album"
                  ? "1"
                  : "0.72"
              }
            />
            <path
              d="M423.234 531.694C411.151 531.694 401.2 541.645 401.2 553.728C401.2 565.812 411.151 575.762 423.234 575.762C435.317 575.762 445.268 565.812 445.268 553.728C445.268 541.645 435.317 531.694 423.234 531.694Z"
              fill={
                window.location.pathname === "/my-albums" ||
                window.location.pathname === "/view-album"
                  ? "#FF9A45"
                  : "#7C8DB5"
              }
              fill-opacity={
                window.location.pathname === "/my-albums" ||
                window.location.pathname === "/view-album"
                  ? "1"
                  : "0.72"
              }
            />
            <path
              d="M266.88 585.997C266.88 573.913 256.929 564.318 244.846 564.318C232.762 564.318 222.812 574.269 222.812 586.352C222.812 598.435 232.762 608.386 244.846 608.386C256.929 608.031 266.88 598.08 266.88 585.997Z"
              fill={
                window.location.pathname === "/my-albums" ||
                window.location.pathname === "/view-album"
                  ? "#FF9A45"
                  : "#7C8DB5"
              }
              fill-opacity={
                window.location.pathname === "/my-albums" ||
                window.location.pathname === "/view-album"
                  ? "1"
                  : "0.72"
              }
            />
            <path
              d="M568.621 204.348H142.155C63.9699 204.348 0 268.318 0 346.503V595.275C0 673.461 63.9699 737.431 142.155 737.431H568.621C646.807 737.431 710.777 673.461 710.777 595.275V346.503C710.777 268.318 646.807 204.348 568.621 204.348ZM498.61 398.39V553.695C498.61 554.406 498.255 554.761 498.255 555.472C497.188 595.986 464.137 629.037 423.268 629.037C381.687 629.037 347.925 595.275 347.925 553.695C347.925 512.114 381.687 478.353 423.268 478.353C431.086 478.353 438.194 479.774 445.302 481.906V433.218L320.205 467.336V585.68V586.035C320.205 627.616 286.443 661.378 244.863 661.378C203.282 661.378 169.52 627.616 169.52 586.035C169.52 544.455 203.282 510.693 244.863 510.693C252.681 510.693 259.789 512.114 266.897 514.247V446.723V389.861C266.897 358.231 286.443 332.643 316.651 324.825L413.672 298.171C444.946 289.997 464.137 298.171 475.154 306.7C485.816 314.874 498.61 331.577 498.61 363.207V398.39Z"
              fill={
                window.location.pathname === "/my-albums" ||
                window.location.pathname === "/view-album"
                  ? "#FF9A45"
                  : "#7C8DB5"
              }
              fill-opacity={
                window.location.pathname === "/my-albums" ||
                window.location.pathname === "/view-album"
                  ? "1"
                  : "0.72"
              }
            />
          </svg>

          <span class="flex-1 ml-3 whitespace-nowrap">My Albums</span>
        </a>
      </li>
      <li>
        <a
          // href="/author-profile"
          className={
            window.location.pathname === "/author-profile"
              ? "flex items-center p-2 text-custom-primary rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:cursor-pointer"
              : "flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:cursor-pointer"
          }
          onClick={() => {
            navigate("/author-profile");
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.0016 11.317C17.2997 11.4753 17.5296 11.7253 17.6915 11.9753C18.0066 12.492 17.981 13.1253 17.6744 13.6837L17.0783 14.6837C16.7632 15.217 16.1755 15.5503 15.5709 15.5503C15.2728 15.5503 14.9407 15.467 14.6682 15.3003C14.4467 15.1587 14.1912 15.1087 13.9187 15.1087C13.0756 15.1087 12.3688 15.8003 12.3432 16.6253C12.3432 17.5837 11.5597 18.3337 10.5803 18.3337H9.42211C8.43422 18.3337 7.65072 17.5837 7.65072 16.6253C7.63369 15.8003 6.92683 15.1087 6.08372 15.1087C5.80268 15.1087 5.54719 15.1587 5.33428 15.3003C5.06176 15.467 4.72111 15.5503 4.43155 15.5503C3.81838 15.5503 3.23075 15.217 2.91565 14.6837L2.32802 13.6837C2.01292 13.142 1.99589 12.492 2.31099 11.9753C2.44725 11.7253 2.70274 11.4753 2.9923 11.317C3.23075 11.2003 3.38405 11.0087 3.52882 10.7837C3.95464 10.067 3.69915 9.12533 2.97526 8.70033C2.13215 8.22533 1.85963 7.16699 2.34506 6.34199L2.91565 5.35866C3.4096 4.53366 4.46562 4.24199 5.31725 4.72533C6.05817 5.12533 7.02051 4.85866 7.45484 4.15033C7.5911 3.91699 7.66775 3.66699 7.65072 3.41699C7.63369 3.09199 7.72737 2.78366 7.88918 2.53366C8.20428 2.01699 8.77487 1.68366 9.39656 1.66699H10.5974C11.2276 1.66699 11.7982 2.01699 12.1133 2.53366C12.2666 2.78366 12.3688 3.09199 12.3432 3.41699C12.3262 3.66699 12.4028 3.91699 12.5391 4.15033C12.9734 4.85866 13.9358 5.12533 14.6852 4.72533C15.5283 4.24199 16.5928 4.53366 17.0783 5.35866L17.6489 6.34199C18.1428 7.16699 17.8703 8.22533 17.0187 8.70033C16.2948 9.12533 16.0393 10.067 16.4736 10.7837C16.6099 11.0087 16.7632 11.2003 17.0016 11.317ZM7.59111 10.0087C7.59111 11.317 8.67268 12.3587 10.0097 12.3587C11.3468 12.3587 12.4028 11.317 12.4028 10.0087C12.4028 8.70033 11.3468 7.65033 10.0097 7.65033C8.67268 7.65033 7.59111 8.70033 7.59111 10.0087Z"
              fill={
                window.location.pathname === "/author-profile"
                  ? "#FF9A45"
                  : "#7C8DB5"
              }
              fill-opacity={
                window.location.pathname === "/author-profile" ? "1" : "0.72"
              }
            />
          </svg>

          <span class="flex-1 ml-3 whitespace-nowrap">Profile</span>
        </a>
      </li>
      <li>
        <a
          // href="#"
          className={
            window.location.pathname === "/logout"
              ? "flex items-center p-2 text-custom-primary rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:cursor-pointer"
              : "flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:cursor-pointer"
          }
          onClick={() => {
            console.log("logout");
            logout();
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 711 712"
            fill="#7C8DB5"
            fill-opacity="0.72"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M526.201 0H433.736C319.933 0 248.806 71.127 248.806 184.93V328.962H471.078C485.659 328.962 497.75 341.054 497.75 355.635C497.75 370.216 485.659 382.308 471.078 382.308H248.806V526.34C248.806 640.143 319.933 711.27 433.736 711.27H525.845C639.649 711.27 710.776 640.143 710.776 526.34V184.93C711.131 71.127 640.004 0 526.201 0Z"
              fill="#7C8DB5"
              fill-opacity="0.72"
            />
            <path
              d="M90.9537 328.956L164.57 255.341C169.905 250.006 172.394 243.249 172.394 236.492C172.394 229.735 169.905 222.622 164.57 217.643C154.257 207.33 137.186 207.33 126.873 217.643L7.73506 336.78C-2.57835 347.093 -2.57835 364.164 7.73506 374.477L126.873 493.615C137.186 503.928 154.257 503.928 164.57 493.615C174.884 483.301 174.884 466.231 164.57 455.917L90.9537 382.301H248.856V328.956H90.9537Z"
              fill="#7C8DB5"
              fill-opacity="0.72"
            />
          </svg>

          <span class="flex-1 ml-3 whitespace-nowrap">Log Out</span>
        </a>
      </li>
    </>
  );
}

export default AuthorMenuOptions;
