import React from "react";

function InputOne(props) {
  return (
    <>
      <input
        type={props.type ? props.type : "text"}
        placeholder={props.placeholder}
        className="input w-full md:w-full lg:w-full border-2 border-black rounded-xl outline-none py-2 px-4"
        // onInput={(e) => {
        //   console.log("INO: ", e.target.value, props.placeholder);
        //   props.onInput(e.target.value, props.placeholder);
        // }}
        onChange={(e) => props.onInput(e)}
        disabled={props.disabled}
        value={props.value}
      />
    </>
  );
}

export default InputOne;
