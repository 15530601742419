import React from "react";
import { useNavigate } from "react-router-dom";

import { Jazzicon } from "@ukstv/jazzicon-react";

import albumcover from "../assets/albumcover_sample.png";

function ArtistCard(props) {
  const navigate = useNavigate();
  function generateJazziconUrl(seed) {
    console.log("Generating Jazzicon!!", seed);
    return (
      <Jazzicon
        address={seed}
        shape="square"
        className="w-20 h-auto md:w-28 lg:w-28 md:h-28 lg:h-28"
      />
    );
  }
  return (
    <div
      className="flex flex-col p-4 px-6 space-y-3 border-custom-text-gray/40 items-left rounded-3xl hover:bg-custom-primary/40 hover:cursor-pointer w-max"
      onClick={() => {
        navigate("/artists/" + props.artistId);
      }}
    >
      {props.image === "" || props.image === undefined? (
        generateJazziconUrl(props.artistId)
      ) : (
        <img
          src={props.image}
          className="object-cover w-20 h-auto rounded-lg md:w-28 lg:w-28 md:h-28 lg:h-28"
        />
      )}
      <div className="flex flex-col items-left">
        <p className="text-sm capitalize md:text-md lg:text-md">
          {props.artistName}
        </p>
        {/* <p className="mt-1 text-xs text-custom-text-gray md:text-sm lg:text-sm">Pop</p> */}
      </div>
    </div>
  );
}

export default ArtistCard;
