import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";
import toast from "react-hot-toast";

import { trimString, checkEmailFormat } from "../utils/utils";
import logo from "../assets/logo.png";
import RoleSelector from "../components/RoleSelector";
import InputOne from "../components/InputOne";
import ButtonOne from "../components/ButtonOne";

function Login(props) {
  const {
    globalState,
    tools,
    emailLogin,
    walletLogin,
    connectWallet,
    disconnectWallet,
  } = useContext(AppContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <>
      <div className="flex flex-col items-center justify-center w-full h-full md:flex-row md:space-x-10 lg:space-x-40 bg-custom-white">
        <div className="flex flex-col items-center justify-center space-y-4 md:w-1/2 lg:w-auto">
          <img src={logo} className="object-contain h-64 md:h-48 lg:h-64" />
          <p className="text-3xl font-bold">ROP&nbsp;&nbsp; PLATFORM</p>
        </div>
        <div className="flex flex-col justify-center w-full px-4 space-y-6 md:w-auto lg:w-auto md:px-0">
          <div className="w-full mt-8 text-center lg:text-left md:text-left md:mt-0 lg:mt-0">
            <p className="text-3xl font-bold">Please Login</p>
          </div>
          {/* <div className="pb-2 ">
            <RoleSelector />
          </div> */}
          <div className="flex flex-col space-y-6 lg:w-80 md:w-80">
            <InputOne
              placeholder="Email"
              onInput={(e) => setEmail(e.target.value)}
              value={email}
              name="email"
            />
            <InputOne
              placeholder="Password"
              onInput={(e) => setPassword(e.target.value)}
              value={password}
              name="password"
              type="password"
            />
            <ButtonOne
              label="Login"
              onClick={() => {
                if (!checkEmailFormat(email)) {
                  toast.error("Please enter a valid email address");
                } else {
                  emailLogin(email, password);
                }
              }}
            />
          </div>
          <div className="py-6 divider">Or</div>
          {globalState.walletConnected === true ? (
            <>
              <div className="flex flex-row items-center justify-center space-x-4">
                <div className="w-4 h-4 rounded-full bg-green"></div>
                <p className="text-center">
                  {trimString(globalState.account, 8, 6)}
                </p>
              </div>
              <ButtonOne
                label="Disconnect Wallet"
                onClick={() => disconnectWallet()}
              />
              <ButtonOne
                label="Login with Connected Wallet"
                onClick={() => {
                  walletLogin(globalState.account);
                }}
              />
            </>
          ) : (
            <ButtonOne label="Connect Wallet" onClick={() => connectWallet()} />
          )}
          <div className="flex flex-col items-center pb-8 mt-8 md:pb-0 lg:pb-0">
            <p>
              Don't have an account?{" "}
              <span
                className="font-bold text-black underline cursor-pointer"
                onClick={() => tools.navigate("/signup")}
              >
                Sign Up
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
