import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { useNavigate } from "react-router-dom";
import { Jazzicon } from "@ukstv/jazzicon-react";
import { toast } from "react-hot-toast";

import Titlebar from "../../components/Titlebar";
import artistProfileSongs from "../../assets/icons/artistProfileSongs.svg";
import artistProfileAlbum from "../../assets/icons/artistProfileAlbum.svg";
import calendarIcon from "../../assets/icons/calendar.svg";
import Tag from "../../components/Tag";
import AddressDisplay from "../../components/AddressDisplay";
import albumcover from "../../assets/albumcover_sample.png";

import InputOne from "../../components/InputOne";
import ButtonOne from "../../components/ButtonOne";
import uploadIcon from "../../assets/icons/uploadIcon.svg";

import { convertTimestamp } from "../../utils/utils";

function AuthorProfile(props) {
  const {
    globalState,
    setGlobalState,
    tools,
    checkJWTStatus,
    updateUserObject,
    imageConverter,
    connectWallet,
    uploadFileToS3,
  } = useContext(AppContext);
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const navigate = useNavigate();
  const [profImage, setProfImage] = useState(null);
  const [modName, setModName] = useState("");
  const [modEmail, setModEmail] = useState("");

  function generateJazziconUrl() {
    if (globalState.userObject.id?.length > 0) {
      console.log("Generating Jazzicon", globalState.userObject.id);
      setProfImage(<Jazzicon address={globalState.userObject.id} />);
      // return <Jazzicon address={globalState.userObject.id} />;
    } else {
      console.log("Not generating Jazzicon");
      return null;
    }
  }

  const handleImageChange = async (e) => {
    console.log("Image Change: ", e.target.files);
    const file = e.target.files[0];
    const url = await uploadFileToS3(file, "images/profilepics");
    let tempUserObject = globalState.userObject;
    console.log("[1] tempUserObject: ", tempUserObject);
    tempUserObject.profileData = {
      ...tempUserObject.profileData,
      profilePicture: url,
    };
    console.log("[2] tempUserObject: ", tempUserObject);
    updateUserObject(tempUserObject);

    // if (file) {
    //   const reader = new FileReader();

    //   reader.onload = () => {
    //     const imageBuffer = reader.result;
    //     console.log("Image Buffer: ", imageBuffer);
    //     const imageArray = new Uint8Array(imageBuffer);
    //     setImageName(file.name);
    //     setImage(imageArray);
    //     let tempUserObject = globalState.userObject;
    //     console.log("[1] tempUserObject: ", tempUserObject);
    //     tempUserObject.profileData = {
    //       ...tempUserObject.profileData,
    //       profilePicture: JSON.stringify(imageArray),
    //     };
    //     console.log("[2] tempUserObject: ", tempUserObject);
    //     updateUserObject(tempUserObject);
    //     setImage(null);
    //     setImageName("");
    //   };

    //   reader.readAsArrayBuffer(file);
    // }
  };

  const initAuthCheck = async () => {
    console.log("[+] Checking if user is authenticated", globalState);
    if (globalState.isAuthenticated === false) {
      console.log("[+] User is not authenticated");
      const status = await checkJWTStatus("Author Profile");
      if (status === false) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    initAuthCheck();
  }, []);

  useEffect(() => {
    generateJazziconUrl();
  }, [globalState.isAuthenticated]);

  return (
    <>
      <Titlebar
        showIcon={false}
        showSearchBar={false}
        pageTitle={"Your Profile"}
      />
      <div className="flex flex-col lg:justify-between items-center p-4 px-6 bg-custom-white rounded-3xl mt-6 md:w-full md:p-8 lg:w-full lg:p-8">
        <div className="flex flex-row justify-start w-full items-center lg:items-start md:items-start ">
          {globalState.userObject.profileData?.profilePicture === null ||
          globalState.userObject.profileData?.profilePicture === undefined ||
          globalState.userObject.profileData?.profilePicture === "" ? (
            <div className="w-20 h-20 lg:w-36 lg:h-36 md:w-28 md:h-28  rounded-2xl">
              {profImage}
            </div>
          ) : (
            <img
              src={globalState.userObject.profileData?.profilePicture}
              alt="albumcover"
              className="w-20 h-20 lg:w-36 lg:h-36 md:w-28 md:h-28  rounded-2xl object-cover"
            />
          )}

          <div className="flex flex-col justify-start ml-4 w-full lg:space-y-4 md:space-y-3 space-y-2">
            <div className="flex flex-row  w-full space-x-4">
              <p className="md:text-4xl  lg:text-5xl font-extrabold capitalize">
                {globalState.userObject.name}
              </p>
            </div>
            <Tag
              label={globalState.userObject.role}
              songCountDisplay={false}
            ></Tag>
            <div className="flex lg:flex-row flex-col  lg:justify-between items-start  lg:space-x-8 space-y-4 lg:pt-0 pt-2">
              <div className="flex flex-row space-x-8">
                {/* <div className="flex flex-row justify-center space-x-2 items-center">
                  <img
                    src={calendarIcon}
                    alt="joinDate"
                    className="lg:w-6 md:h-6 w-4 h-4"
                  />
                  <p className="text-custom-text-gray lg:text-sm md:text-sm text-xs">
                    {convertTimestamp(globalState.userObject.profileData?.date)}
                  </p>
                </div> */}
                <div className="flex flex-row justify-center space-x-2 items-center">
                  <img
                    src={artistProfileSongs}
                    alt="artistProfileSong"
                    className="lg:w-6 md:h-6 w-4 h-4"
                  />
                  <p className="text-custom-text-gray lg:text-sm md:text-sm text-xs">
                    {globalState.stats.totalSongs} Song(s)
                  </p>
                </div>
                <div className="flex flex-row justify-center space-x-2 items-center">
                  <img
                    src={artistProfileAlbum}
                    alt="artistProfileAlbum"
                    className="lg:w-6 md:h-6 w-4 h-4"
                  />
                  <p className="text-custom-text-gray lg:text-sm md:text-sm text-xs">
                    {globalState.stats.countsSum} Album(s)
                  </p>
                </div>
                {globalState.userObject.walletAddress?.length > 0 ? (
                  <AddressDisplay
                    address={
                      globalState.userObject.walletAddress.slice(0, 6) +
                      "..." +
                      globalState.userObject.walletAddress.slice(-4)
                    }
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col p-8 rounded-3xl bg-custom-white mt-6">
        <p className="text-md font-bold mb-6">Edit Profile</p>
        <div className="flex flex-row w-full justify-between">
          <div className="flex flex-col space-y-4 w-fit">
            <InputOne
              placeholder="Enter Name"
              onInput={(e) => {
                console.log("Name: ", e.target.value);
                setModName(e.target.value);
              }}
              value={modName}
              name="modName"
            />
            {/* <InputOne
              placeholder="Enter Email"
              onInput={(e) => {
                console.log("Email: ", e.target.value);
                setModEmail(e.target.value);
              }}
              value={modEmail}
              name="modEmail"
            /> */}
          </div>
          <div className="flex flex-col items-center space-y-4">
            <p className="font-bold">
              {globalState.userObject.walletAddress?.length > 0 ||
              globalState.account?.length > 0
                ? globalState.userObject.walletAddress.length > 0
                  ? globalState.userObject.walletAddress.substring(0, 6) +
                    "..." +
                    globalState.userObject.walletAddress.substring(
                      globalState.userObject.walletAddress.length - 4,
                      globalState.userObject.walletAddress.length
                    )
                  : globalState.account.substring(0, 6) +
                    "..." +
                    globalState.account.substring(
                      globalState.account.length - 4,
                      globalState.account.length
                    )
                : "Please connect your wallet"}
            </p>
            {globalState.userObject.walletAddress?.length === 0 ? (
              globalState.account.length === 0 ? (
                <button
                  className="btn bg-custom-primary text-white border-0 rounded-xl capitalize text-md hover:bg-black w-80"
                  onClick={async () => {
                    await connectWallet();
                  }}
                >
                  Connect Wallet
                </button>
              ) : (
                <button
                  className="btn bg-custom-primary/50 text-white/50 border-0 rounded-xl capitalize text-md hover:bg-custom-primary/50 w-80 disabled cursor-not-allowed"
                  onClick={() => {
                    connectWallet();
                  }}
                >
                  Wallet Connected
                </button>
              )
            ) : (
              <>
                <button
                  className="btn bg-custom-primary/50 text-white/50 border-0 rounded-xl capitalize text-md hover:bg-custom-primary/50 w-80 disabled cursor-not-allowed"
                  onClick={() => {
                    connectWallet();
                  }}
                >
                  Wallet Connected
                </button>
              </>
            )}
          </div>
        </div>
        <div className="relative flex flex-col justify-center rounded-3xl bg-custom-text-gray w-fit py-12 mt-4">
          <input
            //   ref={fileInputRef}
            type="file"
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            onChange={(e) => {
              console.log("image changed");
              handleImageChange(e);
            }}
          />
          <img
            src={uploadIcon}
            alt="uploadIcon"
            className="w-12 h-12 mx-auto"
          />
          <p className="text-sm font-bold text-custom-white p-2 hover:cursor-pointer w-44 text-center">
            Profile Picture
          </p>
        </div>

        <div className="flex flex-row justify-between w-full mt-8">
          <ButtonOne
            label="Save Changes"
            onClick={() => {
              if (
                globalState.userObject.walletAddress.length === 0 &&
                globalState.account.length > 0
              ) {
                console.log(
                  "updating wallet",
                  globalState.account,
                  globalState
                );
                let tempUserObject = globalState.userObject;
                console.log("[1] tempUserObject: ", tempUserObject);
                tempUserObject.walletAddress = globalState.account;
                console.log("[2] tempUserObject: ", tempUserObject);
                updateUserObject(tempUserObject);
              }
              if (modName.length > 0 && modEmail.length === 0) {
                let tempUserObject = globalState.userObject;
                tempUserObject.name = modName;
                updateUserObject(tempUserObject);
                setModName("");
              } else if (modEmail.length > 0 && modName.length === 0) {
                let tempUserObject = globalState.userObject;
                tempUserObject.email = modEmail;
                updateUserObject(tempUserObject);
                setModEmail("");
              } else if (modName.length > 0 && modEmail.length > 0) {
                let tempUserObject = globalState.userObject;
                tempUserObject.name = modName;
                tempUserObject.email = modEmail;
                updateUserObject(tempUserObject);
                setModName("");
                setModEmail("");
              } else {
                toast.error("Nothing to Modify");
              }
            }}
          />
          <ButtonOne
            alert={true}
            label="Delete Account"
            onClick={() => {
              toast.success(
                "Request Received. Account will be deleted by the Admin"
              );
            }}
          />
        </div>
      </div>
    </>
  );
}

export default AuthorProfile;
