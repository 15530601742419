import React from "react";

function Tag(props) {
  return (
    <>
      {props.songCountDisplay === false ? (
        <div className="bg-custom-primary text-white font-bold rounded-full px-10 py-2 text-center text-sm w-fit capitalize">
          <p>{props.label}</p>
        </div>
      ) : (
        <div className="bg-custom-primary text-white lg:font-bold md:font-bold font-medium rounded-full lg:px-10 md:px-4 px-4 text-xs  py-2 text-center lg:text-sm md:text-xs  w-fit">
          <p>{props.label}&nbsp; &nbsp;Play Counts</p>
        </div>
      )}
    </>
  );
}

export default Tag;
