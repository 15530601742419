import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";

import Titlebar from "../../components/Titlebar";
import DetailedSongCard from "../../components/DetailedSongCard";

function TopSongs(props) {
  const { globalState, imageConverter } = useContext(AppContext);
  const [songCards, setSongCards] = useState([]);
  const navigate = useNavigate();

  const processSongCards = () => {
    console.log(
      "Processing Song Cards",
      globalState.onChainSongData,
      globalState.allAlbums
    );
    let tempArr = [];
    if (globalState.allAlbums.length > 0) {
      globalState.allAlbums.forEach((album) => {
        album.songs.forEach((songObj) => {
          const {
            songId,
            songName,
            songCover,
            albumName,
            albumId,
            timestamp,
            playCounts,
            song,
          } = songObj;
          if (globalState.onChainSongData[songId] !== undefined) {
            const playCount = globalState.onChainSongData[songId][2];
            let isLike = globalState.likedSongs.includes(songId);
            const card = (
              <DetailedSongCard
                key={songId}
                songId={songId}
                songName={songName}
                songImage={imageConverter(songCover)}
                albumName={albumName}
                albumId={albumId}
                timestamp={timestamp}
                playCount={playCounts}
                showPlayCount={true}
                showReleased={true}
                showLike={true}
                isLiked={
                  globalState.userObject.likedSongs.includes(songId)
                    ? true
                    : false
                }
                releaseDate={timestamp}
                songUrl={songObj.song}
                authorName={albumName}
              />
            );
            tempArr.push(card);
          }
        });
      });
      tempArr.sort((a, b) => b.props.playCount - a.props.playCount);
      setSongCards(tempArr);
      console.log("Song Cards", tempArr);
    }
  };

  useEffect(() => {
    processSongCards();
  }, [globalState.onChainSongData]);

  return (
    <>
      <Titlebar pageTitle="Explore Songs" showSearchBar={false} />
      <div className="flex flex-col lg:justify-between items-center p-4 px-6 bg-custom-white rounded-3xl mt-6 md:w-full md:p-8 lg:w-full lg:p-8 ">
        {/* <div className="flex flex-row justify-between w-full items-center ">
          <p className="text-md font-bold">Top Songs</p>
        </div> */}
        {songCards.length > 0 ? (
          <div className="flex flex-col items-center space-y-6 w-full mt-2">
            {songCards}
          </div>
        ) : (
          <div className="flex flex-col justify-center  items-center space-y-2 text-center py-16">
            <p className="text-2xl lg:text-4xl md:text-4xl  font-bold text-custom-text-gray">
              No Songs to display
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default TopSongs;
