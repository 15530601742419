import React, { useContext, useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";

import { AppContext } from "../AppContext";
import FaPlay from "../assets/icons/FaPlay.svg";
import FaPause from "../assets/icons/FaPause.svg";
import FaForward from "../assets/icons/FaForward.svg";
import FaBackward from "../assets/icons/FaBackward.svg";
// import song from "../assets/song.mp3"; // Replace with your audio file
import albumArt from "../assets/music-placeholder.png";
import useWindowSize from "../utils/useWindowSize";

function Player(props) {
  const {
    globalState,
    playSong,
    markValidPlayCount,
    getSongByUrl,
    setGlobalState,
  } = useContext(AppContext);
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [songName, setSongName] = useState("Song Name");
  const [artistName, setArtistName] = useState("Artist Name");
  const [songUrl, setSongUrl] = useState("");
  // const [audioRef, setAudioRef] = useState(new Audio(songUrl));
  const [audioAvail, setAudioAvail] = useState(false);
  const [markedCount, setMarkedCount] = useState(false);
  const { isMdScreen, isLgScreen } = useWindowSize();

  const handlePlayPause = (component, url) => {
    console.log("Play/Pause", songUrl, url);
    if (songUrl === "" && url === "") {
      toast.error("No song to play");
      return;
    } else {
      console.log("component: ", component);
      console.log("1");
      if (component === true) {
        console.log("2");
        if (isPlaying) {
          console.log("3");
          audioRef.current.pause();
          setIsPlaying(!isPlaying);
        } else {
          console.log("4");
          console.log("Song URL: ", songUrl, audioAvail);
          audioRef.current.play();
          setIsPlaying(!isPlaying);
        }
      } else {
        console.log("5");
        console.log("Song URL False: ", url);
        audioRef.current.play();
        setIsPlaying(true);
      }
    }
  };

  // const handleLoadedData = () => {
  //   setDuration(audioRef.current.duration);
  // };

  const handleLoadedData = () => {
    setDuration(audioRef.current.duration);
    audioRef.current.removeEventListener("loadedmetadata", handleLoadedData);
  };

  let timeFlag = false;

  const handleTimeUpdate = () => {
    const currentTimeInSeconds = Math.floor(audioRef.current.currentTime);
    // console.log("Time Update", currentTimeInSeconds);
    setCurrentTime(currentTimeInSeconds);

    if (
      currentTimeInSeconds >= 5 &&
      currentTimeInSeconds <= 6 &&
      !audioRef.current.paused &&
      !audioRef.current.ended &&
      !timeFlag
    ) {
      if (timeFlag === false) {
        markValidPlayCount(globalState.liveSong.song.id);
        timeFlag = true;
      }
    }
  };

  const handleNext = () => {
    console.log("Handle Next", songUrl);
    if (globalState.liveSong.playlist) {
      if (globalState.liveSong.playlist.length > 0) {
        const playlist = globalState.liveSong.playlist;
        const currentIndex = playlist.findIndex((url) => url === songUrl);

        if (currentIndex !== -1) {
          const nextIndex = (currentIndex + 1) % playlist.length;
          const nextSongUrl = playlist[nextIndex];
          setupNewPlaySong(nextSongUrl);
        } else {
          console.log("Current song not found in the playlist");
          // Logic for handling current song not found
        }
      } else {
        toast.error("Select a Song from the Playlist");
      }
    } else {
      toast.error("Select a Song from the Playlist");
    }
  };

  const handlePrevious = () => {
    if (globalState.liveSong.playlist) {
      if (globalState.liveSong.playlist.length > 0) {
        const playlist = globalState.liveSong.playlist;
        const currentIndex = playlist.findIndex((url) => url === songUrl);

        if (currentIndex !== -1) {
          const previousIndex =
            (currentIndex - 1 + playlist.length) % playlist.length;
          const previousSongUrl = playlist[previousIndex];
          setupNewPlaySong(previousSongUrl);
        } else {
          console.log("Current song not found in the playlist");
          // Logic for handling current song not found
        }
      } else {
        toast.error("Select a Song from the Playlist");
      }
    } else {
      toast.error("Select a Song from the Playlist");
    }
  };

  const setupNewPlaySong = async (url) => {
    console.log("Setup New Play Song", url);
    if (url !== "") {
      setSongUrl(url);
      getSongByUrl(url)
        .then((songData) => {
          let filteredSongData = songData.song;
          console.log("Song Data:", songData, filteredSongData);
          if (filteredSongData !== null) {
            setSongName(filteredSongData.songName);
            setGlobalState((prevState) => {
              return {
                ...prevState,
                liveSong: {
                  ...prevState.liveSong,
                  song: {
                    name: filteredSongData.songName,
                    artist: "",
                    url: filteredSongData.song,
                    cover: filteredSongData.songCover,
                    id: filteredSongData.songId,
                  },
                },
              };
            });
            if (audioRef.current) {
              audioRef.current.pause();
              audioRef.current = new Audio(globalState.liveSong.song.url);
              audioRef.current.load();
              audioRef.current.addEventListener("loadeddata", handleLoadedData);
              audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
              audioRef.current.addEventListener("ended", handleNext);
              handlePlayPause(false, globalState.liveSong.song.url);
            }
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.error("Error:", error);
        });
    }

    return () => {
      // Cleanup logic for event listeners
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.removeEventListener("loadeddata", handleLoadedData);
        audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        audioRef.current.removeEventListener("ended", handleNext);
      }
    };
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  useEffect(() => {
    console.log("Song Clicked", globalState.liveSong);
    if (globalState.liveSong.song.url !== "") {
      setSongName(globalState.liveSong.song.name);
      setArtistName(globalState.liveSong.song.artist);
      setSongUrl(globalState.liveSong.song.url);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = new Audio(globalState.liveSong.song.url);
        audioRef.current.load();
        audioRef.current.addEventListener("loadeddata", handleLoadedData);
        audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
        audioRef.current.addEventListener("ended", handleNext);
        handlePlayPause(false, globalState.liveSong.song.url);
      }
    }

    return () => {
      // Cleanup logic for event listeners
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.removeEventListener("loadeddata", handleLoadedData);
        audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        audioRef.current.removeEventListener("ended", handleNext);
      }
    };
  }, [globalState.liveSong.song.url]);

  return (
    <div className="px-4 p-4 w-full flex rounded-t-2xl bg-white">
      <div className="flex flex-row w-full justify-between">
        <div className="flex flex-row space-x-4 items-center mr-16">
          <img
            src={
              globalState.liveSong.song.cover === ""
                ? albumArt
                : globalState.liveSong.song.cover
            }
            alt="Album Art"
            className="w-12 h-12  md:w-14 md:h-14  lg:w-16 lg:h-16 rounded-lg"
          />
          <div className="flex flex-col">
            <div className="text-md text-black overflow-hidden">
              {globalState.liveSong.song.name === "" ? (
                "-"
              ) : isLgScreen === true ? (
                <p
                  className={
                    globalState.liveSong.song.name.length > 19
                      ? "animate-marquee whitespace-nowrap"
                      : "whitespace-nowrap"
                  }
                >
                  {globalState.liveSong.song.name}1
                </p>
              ) : isMdScreen === true ? (
                <p
                  className={
                    globalState.liveSong.song.name.length > 7
                      ? " animate-marquee whitespace-nowrap"
                      : " whitespace-nowrap"
                  }
                >
                  {globalState.liveSong.song.name}2
                </p>
              ) : (
                <p
                  className={
                    globalState.liveSong.song.name.length > 5
                      ? "animate-marquee whitespace-nowrap"
                      : "whitespace-nowrap"
                  }
                >
                  {globalState.liveSong.song.name}3
                </p>
              )}
            </div>
            <div className="text-xs text-custom-text-gray">
              {globalState.liveSong.song.artist === ""
                ? "-"
                : globalState.liveSong.song.artist}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center space-between space-x-2 w-full px-4">
          <div className="text-sm text-black">{formatTime(currentTime)}</div>
          <input
            type="range"
            min="0"
            max={duration}
            value={currentTime}
            onChange={(e) => {
              audioRef.current.currentTime = e.target.value;
              setCurrentTime(e.target.value);
            }}
            className="w-full"
          />
          <div className="text-sm text-black">{formatTime(duration)}</div>
        </div>
        <div className="flex flex-row items-center lg:space-x-5 md:space-x-4 space-x-2  ml-4">
          <button className="" onClick={handlePrevious}>
            <img
              src={FaBackward}
              className="lg:w-6 lg:h-6 md:w-4 md:h-4 w-4 h-4"
            />
          </button>
          <button className="" onClick={() => handlePlayPause(true)}>
            {isPlaying ? <img src={FaPause} /> : <img src={FaPlay} />}
          </button>
          <button onClick={handleNext}>
            <img src={FaForward} className="w-6 h-6" />
          </button>
        </div>
        <audio
          ref={audioRef}
          onTimeUpdate={handleTimeUpdate}
          onLoadedData={handleLoadedData}
          onEnded={handleNext}
          // src="https://rop-poc.s3.eu-north-1.amazonaws.com/RollingintheDeepmp3-1684780634008"
          src={songUrl}
        />
      </div>
    </div>
  );
}

export default Player;
