import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";
import toast from "react-hot-toast";

import { trimString, checkEmailFormat } from "../utils/utils";
import logo from "../assets/logo.png";
import RoleSelector from "../components/RoleSelector";
import InputOne from "../components/InputOne";
import ButtonOne from "../components/ButtonOne";

function Signup(props) {
  const {
    globalState,
    tools,
    connectWallet,
    disconnectWallet,
    walletSignup,
    emailSignup,
  } = useContext(AppContext);

  const [role, setRole] = useState("listener");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [walletName, setWalletName] = useState("");

  return (
    <>
      <div className="flex flex-col items-center w-full h-full mt-8 md:flex-row md:justify-center lg:justify-center md:space-x-10 lg:space-x-40 md:mt-0 lg:mt-0 bg-custom-white">
        <div className="flex flex-col items-center justify-center space-y-4 md:w-1/2 lg:w-auto">
          <img src={logo} className="object-contain h-64 md:h-48 lg:h-64" />
          <p className="text-3xl font-bold">ROP&nbsp;&nbsp; PLATFORM</p>
        </div>
        <div className="flex flex-col self-start justify-center w-full px-4 space-y-6 md:w-auto lg:w-auto md:px-0">
          <div className="w-full mt-8 text-center  lg:text-left md:text-left md:mt-8 lg:mt-8">
            <p className="text-3xl font-bold">Signup</p>
          </div>
          <div className="pb-2 ">
            <RoleSelector setRole={setRole} />
          </div>
          <div className="flex flex-col space-y-6">
            <InputOne
              placeholder="Name"
              onInput={(e) => setName(e.target.value)}
              value={name}
              name="name"
            />
            <InputOne
              placeholder="Email"
              onInput={(e) => setEmail(e.target.value)}
              value={email}
              name="email"
            />
            <InputOne
              placeholder="Password"
              onInput={(e) => setPassword(e.target.value)}
              value={password}
              name="password"
              type="password"
            />
            <InputOne
              placeholder="Confirm Password"
              onInput={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              name="confirmPassword"
              type="password"
            />
            <ButtonOne
              label="Signup"
              onClick={() => {
                if (
                  name === "" ||
                  email === "" ||
                  password === "" ||
                  confirmPassword === ""
                ) {
                  toast.error("Please fill all the fields");
                  return;
                } else {
                  if (password !== confirmPassword) {
                    toast.error("Passwords do not match");
                  } else {
                    if (!checkEmailFormat(email)) {
                      toast.error("Please enter a valid email address");
                    } else {
                      emailSignup(name, email, password, role);
                    }
                  }
                }
              }}
            />
          </div>
          <div className="py-6 divider">Or</div>
          <div className="flex flex-col space-y-6">
            <InputOne
              placeholder="Name"
              onInput={(e) => setWalletName(e.target.value)}
              value={walletName}
              name="walletName"
            />
            {globalState.walletConnected === true ? (
              <>
                <div className="flex flex-row items-center justify-center space-x-4">
                  <div className="w-4 h-4 rounded-full bg-green"></div>
                  <p className="text-center">
                    {trimString(globalState.account, 8, 6)}
                  </p>
                </div>
                <ButtonOne
                  label="Disconnect Wallet"
                  onClick={() => disconnectWallet()}
                />
                <ButtonOne
                  label="Signup with Connected Wallet"
                  onClick={() => {
                    if (walletName === "") {
                      toast.error("Please enter your name");
                      return;
                    } else {
                      walletSignup(walletName, role);
                    }
                  }}
                />
              </>
            ) : (
              <ButtonOne
                label="Connect Wallet"
                onClick={() => connectWallet()}
              />
            )}
          </div>

          <div className="flex flex-col items-center pb-8 mt-8 md:pb-8 lg:pb-8">
            <p>
              Already have an account?{" "}
              <span
                className="font-bold text-black underline cursor-pointer"
                onClick={() => tools.navigate("/login")}
              >
                Login
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
