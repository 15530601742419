import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";

import songImage from "../assets/song.png";
import playIcon from "../assets/icons/playone.svg";
import likedIcon from "../assets/icons/liked.svg";
import likableIcon from "../assets/icons/likable.svg";
import useWindowSize from "../utils/useWindowSize";

function DetailedSongCard(props) {
  const { globalState, playSong, setGlobalState, updateUserObject } =
    useContext(AppContext);
  const { isMdScreen, isLgScreen } = useWindowSize();
  const [getLike, setGetLike] = useState("db");
  const [localLike, setLocalLike] = useState(props.isLiked);

  const formatPlayCount = (value) => {
    value = parseInt(value);
    if (value < 1000) {
      return value.toString();
    } else if (value < 10000) {
      return (value / 1000).toFixed(2) + "k";
    } else {
      return Math.floor(value / 1000).toString() + "k";
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div className="flex flex-col lg:flex-row md:flex-col  justify-between p-2 w-full items-center rounded-2xl px-4 ">
      <div className="flex flex-row items-center justify-start lg:space-between space-x-4 md:space-x-4 md:w-full w-full lg:w-fit">
        {/* <div className="flex flex-col w-12 h-12">
          <img
            src={props.songImage}
            className="w-12 h-12 md:w-12 md:h-12 lg:w-14 lg:h-14 rounded-lg object-cover"
            alt="Song Image"
          />
        </div> */}
        <div className="flex flex-col w-14 h-14">
          <div
            className="w-12 h-12 md:w-12 md:h-12 lg:w-14 lg:h-14 rounded-lg overflow-hidden"
            style={{ aspectRatio: "1/1" }}
          >
            <img
              src={props.songImage}
              className="w-full h-full object-cover"
              alt="Song Image"
            />
          </div>
        </div>

        <div className="flex flex-col items-left overflow-hidden w-44">
          {isLgScreen === true ? (
            <p
              className={
                props.songName.length > 19
                  ? "animate-marquee whitespace-nowrap"
                  : "whitespace-nowrap"
              }
            >
              {props.songName}1
            </p>
          ) : isMdScreen === true ? (
            <p
              className={
                props.songName.length > 7
                  ? " animate-marquee whitespace-nowrap"
                  : " whitespace-nowrap"
              }
            >
              {props.songName}2
            </p>
          ) : (
            <p
              className={
                props.songName.length > 5
                  ? "animate-marquee whitespace-nowrap"
                  : "whitespace-nowrap"
              }
            >
              {props.songName}3
            </p>
          )}
          <p className="text-sm text-custom-text-gray md:text-xs capitalize">
            {props.authorName !== undefined
              ? props.authorName
              : props.albumName}
          </p>
        </div>
      </div>
      <div className="flex flex-row items-center lg:justify-end justify-between space-x-4  lg:space-x-12 md:w-full md:justify-between md:mt-6 lg:mt-0 md:px-2 mt-4 w-full">
        <div className="flex flex-col md:hidden">
          <p className="text-xs font-light text-custom-text-gray">Album</p>
          <p className="text-xs lg:text-sm md:text-sm">WarSongs</p>
        </div>
        {props.showAdded === true ? (
          <div className="lg:flex md:hidden flex-col lg:visible md:visible hidden">
            <p className="text-xs font-light text-custom-text-gray">Added</p>
            <p className="text-xs lg:text-sm md:text-sm">Apr 07, 2023</p>
          </div>
        ) : null}
        {props.showRoyaltyPercentage === true ? (
          <div className="lg:flex md:hidden flex-col lg:visible md:visible hidden">
            <p className="text-xs font-light text-custom-text-gray">
              Royalty %
            </p>
            <p className="text-xs lg:text-sm md:text-sm">
              {props.royaltyPercentage} %
            </p>
          </div>
        ) : null}
        {props.showReleased === true ? (
          <div className="lg:flex md:hidden flex-col lg:visible hidden">
            <p className="text-xs font-light text-custom-text-gray">Released</p>
            <p className="text-xs lg:text-sm md:text-sm">
              {formatDate(props.releaseDate)}
            </p>
          </div>
        ) : null}
        {props.showRoyalty === true ? (
          <div className="lg:flex md:flex flex-col lg:visible md:visible hidden">
            <p className="text-xs font-light text-custom-text-gray">Royalty</p>
            <p className="text-xs lg:text-sm md:text-sm">
              $ {props.royaltyValue}
            </p>
          </div>
        ) : null}
        <div
          className={
            props.showPlayCount === true
              ? "flex flex-row items-center bg-custom-text-gray/30 p-2 rounded-lg space-x-2 hover:bg-custom-text-gray/40 hover:cursor-pointer"
              : "flex flex-row items-center  p-2 rounded-lg space-x-2 hover:cursor-pointer"
          }
          onClick={() => {
            setGlobalState((prevState) => {
              return {
                ...prevState,
                liveSong: {
                  song: {
                    name: props.songName,
                    artist: props.authorName,
                    url: props.songUrl,
                    cover: props.songImage,
                    id: props.songId,
                  },
                  playlist: props.playlist,
                },
              };
            });
          }}
        >
          <svg
            width="10"
            height="13"
            viewBox="0 0 10 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.65909 7.57169C9.61648 7.63001 9.41761 7.87789 9.26136 8.03828L9.17614 8.12576C7.98295 9.45261 5.0142 11.4502 3.50852 12.0917C3.50852 12.1063 2.61364 12.4854 2.1875 12.5H2.13068C1.47727 12.5 0.866477 12.1209 0.553977 11.5085C0.383523 11.1731 0.227273 10.1962 0.213068 10.1817C0.0852273 9.3068 0 7.96683 0 6.49271C0 4.94714 0.0852273 3.54739 0.241477 2.68712C0.241477 2.67254 0.397727 1.88518 0.497159 1.62272C0.653409 1.24362 0.9375 0.922843 1.29261 0.718712C1.5767 0.572904 1.875 0.5 2.1875 0.5C2.5142 0.514581 3.125 0.734751 3.36648 0.835358C4.95739 1.47691 7.99716 3.57655 9.16193 4.85966C9.3608 5.06379 9.57386 5.31167 9.63068 5.36999C9.87216 5.69077 10 6.08445 10 6.50875C10 6.88639 9.88636 7.26549 9.65909 7.57169Z"
              fill={props.showPlayCount === true ? "#FF9A45" : "#FF9A45"}
              fill-opacity="0.72"
            />
          </svg>

          {props.showPlayCount === true ? (
            <p className="text-xs text-black w-4">{props.playCount}</p>
          ) : null}
        </div>
        {props.showLike === true ? (
          <img
            src={
              getLike === "db"
                ? props.isLiked === true
                  ? likedIcon
                  : likableIcon
                : localLike === true
                ? likedIcon
                : likableIcon
            }
            className="w-4 h-4 md:w-4 md:h-4 lg:w-4 lg:h-4 hover:cursor-pointer"
            alt="Like Icon"
            onClick={() => {
              console.log(
                "Like Icon Clicked",
                globalState.userObject,
                props.songId
              );
              setGetLike("local");
              setLocalLike(!localLike);
              if (localLike === false) {
                console.log("Liked");
                let tempUserObject = globalState.userObject;
                tempUserObject.likedSongs.push(props.songId);
                updateUserObject(tempUserObject, true);
              } else {
                console.log("Unliked");
                let tempUserObject = globalState.userObject;
                tempUserObject.likedSongs = tempUserObject.likedSongs.filter(
                  (song) => song !== props.songId
                );
                updateUserObject(tempUserObject, true);
              }
            }}
          />
        ) : null}
        {props.showDistribution === true ? (
          <div
            className={
              props.showPlayCount === true
                ? "flex flex-row items-center bg-custom-text-gray/30 p-2 rounded-lg space-x-2 hover:bg-custom-text-gray/40 hover:cursor-pointer"
                : "flex flex-row items-center  p-2 rounded-lg space-x-2 hover:cursor-pointer"
            }
            onClick={() => {
              console.log("Modal Toggle", globalState.songOverlay);
              props.toggleModalFunction();
              setGlobalState((prevState) => {
                return {
                  ...prevState,
                  songOverlay: {
                    contributors: props.contributors,
                    royaltyValue: props.royaltyValueNoFixed,
                  },
                };
              });
            }}
          >
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.75903 7.19324C6.75903 7.63564 6.88192 7.70119 7.16048 7.7995L7.57831 7.94697V6.5542H7.33253C7.0212 6.5542 6.75903 6.84095 6.75903 7.19324Z"
                fill="#FF9A45"
              />
              <path
                d="M8.80713 11.0607H9.05291C9.37243 11.0607 9.62641 10.774 9.62641 10.4217C9.62641 9.97929 9.50351 9.91375 9.22496 9.81544L8.80713 9.66797V11.0607Z"
                fill="#FF9A45"
              />
              <path
                d="M14.4029 3.46534L12.7234 5.14486C12.6005 5.26775 12.4448 5.3251 12.2892 5.3251C12.1335 5.3251 11.9778 5.26775 11.8549 5.14486C11.6173 4.90727 11.6173 4.51402 11.8549 4.27643L13.5345 2.59691C12.0925 1.36799 10.2328 0.614258 8.19277 0.614258C3.67036 0.614258 0 4.28462 0 8.80703C0 13.3294 3.67036 16.9998 8.19277 16.9998C12.7152 16.9998 16.3855 13.3294 16.3855 8.80703C16.3855 6.76703 15.6318 4.90727 14.4029 3.46534ZM9.62651 8.65956C10.1508 8.84799 10.8554 9.22486 10.8554 10.4292C10.8554 11.4533 10.0443 12.2971 9.05301 12.2971H8.80723V12.502C8.80723 12.8379 8.52867 13.1164 8.19277 13.1164C7.85687 13.1164 7.57831 12.8379 7.57831 12.502V12.2971H7.51277C6.42313 12.2971 5.53012 11.3796 5.53012 10.249C5.53012 9.90486 5.80867 9.62631 6.14458 9.62631C6.48048 9.62631 6.75904 9.90486 6.75904 10.2408C6.75904 10.6914 7.09494 11.06 7.51277 11.06H7.57831V9.24125L6.75904 8.9545C6.2347 8.76607 5.53012 8.3892 5.53012 7.18486C5.53012 6.16076 6.3412 5.31691 7.33253 5.31691H7.57831V5.12028C7.57831 4.78438 7.85687 4.50582 8.19277 4.50582C8.52867 4.50582 8.80723 4.78438 8.80723 5.12028V5.3251H8.87277C9.96241 5.3251 10.8554 6.24269 10.8554 7.37329C10.8554 7.7092 10.5769 7.98775 10.241 7.98775C9.90506 7.98775 9.62651 7.7092 9.62651 7.37329C9.62651 6.92269 9.2906 6.55402 8.87277 6.55402H8.80723V8.37281L9.62651 8.65956Z"
                fill="#FF9A45"
              />
              <path
                d="M16.9508 0.376867C16.8852 0.229398 16.7705 0.106506 16.6149 0.0409638C16.5411 0.0163855 16.4674 0 16.3855 0H13.1084C12.7725 0 12.4939 0.278554 12.4939 0.614458C12.4939 0.950361 12.7725 1.22892 13.1084 1.22892H14.9026L13.5344 2.59711C13.8457 2.86747 14.1325 3.15422 14.4028 3.46554L15.771 2.09735V3.89157C15.771 4.22747 16.0496 4.50602 16.3855 4.50602C16.7214 4.50602 16.9999 4.22747 16.9999 3.89157V0.614458C16.9999 0.53253 16.9835 0.458795 16.9508 0.376867Z"
                fill="#FF9A45"
              />
            </svg>

            {props.showPlayCount === true ? (
              <p className="text-xs text-black">View</p>
            ) : null}
          </div>
        ) : null}
        {props.isContributor === true ? (
          <p className="text-sm">{props.contributeValue}</p>
        ) : null}
      </div>
    </div>
  );
}

export default DetailedSongCard;
