import React from "react";
import { trimString } from "../utils/utils";

function AddressDisplay(props) {
  return (
    <div className="flex flex-row items-center justify-center space-x-4">
      <div className="w-4 h-4 bg-green rounded-full"></div>
      {/* <p className="text-center">{trimString(globalState.account, 8, 6)}</p> */}
      <p className="text-center md:text-sm text-sm lg:text-md">{props.address}</p>
    </div>
  );
}

export default AddressDisplay;
