import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { Jazzicon } from "@ukstv/jazzicon-react";

import Titlebar from "../../components/Titlebar";
import albumcover from "../../assets/albumcover_sample.png";
import artistProfileAlbum from "../../assets/icons/artistProfileAlbum.svg";
import artistProfileSongs from "../../assets/icons/artistProfileSongs.svg";
import verified from "../../assets/icons/verified.svg";
import Tag from "../../components/Tag";
import ArtistCard from "../../components/ArtistCard";
import DetailedSongCard from "../../components/DetailedSongCard";

function ArtistProfile(props) {
  const { artistName } = useParams();
  const navigate = useNavigate();
  const { globalState, checkJWTStatus, getAlbumsOfAuthor, imageConverter } =
    useContext(AppContext);
  const [authorData, setAuthorData] = useState({
    _id: "",
    name: "",
    profileData: {
      profilePicture: "",
    },
  });
  const [authorAlbums, setAuthorAlbums] = useState([]);
  const [authorStats, setAuthorStats] = useState({
    albumCount: 0,
    songCount: 0,
    playCount: 0,
  });
  const [albumCards, setAlbumCards] = useState([]);
  const [songCards, setSongCards] = useState([]);

  const initAuthCheck = async () => {
    console.log("[+] Checking if user is authenticated", globalState);
    if (globalState.isAuthenticated === false) {
      console.log("[+] User is not authenticated");
      const status = await checkJWTStatus("Author Dashoboard");
      if (status === false) {
        navigate("/login");
      } else {
        getAlbumsOfAuthor(globalState.userObject.id);
        getAuthorData(artistName);
      }
    } else {
      getAlbumsOfAuthor(globalState.userObject.id);
      getAuthorData(artistName);
    }
  };

  const processAlbumCards = (albums) => {
    let tempArr = [];
    if (albums.length > 0) {
      const card = albums.map((album) => {
        return (
          <ArtistCard
            artistName={album.albumName}
            artistId={album.albumId}
            image={album.albumCover}
          />
        );
      });
      tempArr.push(card);
      setAlbumCards(tempArr);
    }
  };

  const processSongCards = (songs) => {
    let tempArr = [];
    if (songs.length > 0) {
      const card = songs.map((song) => {
        const {
          songId,
          songName,
          songCover,
          albumName,
          albumId,
          timestamp,
          playCounts,
        } = song;
        return (
          <DetailedSongCard
            key={songId}
            songId={songId}
            songName={songName}
            songImage={imageConverter(songCover)}
            albumName={albumName}
            albumId={albumId}
            timestamp={timestamp}
            playCount={playCounts}
            showPlayCount={true}
            showReleased={true}
            showLike={false}
            isLiked={
              globalState.userObject.likedSongs.includes(songId) ? true : false
            }
            releaseDate={timestamp}
          />
        );
      });
      tempArr.push(card);
      setSongCards(tempArr);
    }
  };

  const getAuthorData = async (id) => {
    // find matching author object in an array of objects using the id and look for key _id
    const authorObject = globalState.allAuthors.find(
      (author) => author._id === artistName
    );
    console.log("[+] Author Object", authorObject, globalState.allAlbums);
    let authorAlbums = [];
    if (authorObject) {
      authorAlbums = globalState.allAlbums.filter(
        (album) => album.albumAuthor === authorObject.id
      );
      setAuthorAlbums(authorAlbums);
      processAlbumCards(authorAlbums);
      let songCount = 0;
      let playCount = 0;
      let songsArray = [];
      authorAlbums.forEach((album) => {
        songCount += album.songs.length;
        album.songs.forEach((song) => {
          playCount += song.playCounts;
          songsArray.push(song);
          processSongCards(songsArray);
        });
        setAuthorStats({
          albumCount: authorAlbums.length,
          songCount: songCount,
          playCount: playCount,
        });
      });
    }
    setAuthorData(authorObject);
  };

  useEffect(() => {
    initAuthCheck();
  }, []);

  return (
    <>
      <Titlebar
        pageTitle="Artist Profile"
        showIcon={true}
        previousPage="/explore-artists"
        showSearchBar={false}
      />
      <div className="flex flex-col lg:justify-between items-center p-4 px-6 bg-custom-white rounded-3xl mt-6 md:w-full md:p-8 lg:w-full lg:p-8">
        <div className="flex flex-row justify-start w-full items-center lg:items-start md:items-start ">
          {authorData ? (
            authorData.profileData ? (
              authorData.profileData.profilePicture ? (
                <img
                  src={authorData.profileData.profilePicture}
                  alt="albumcover"
                  className="w-20 h-20 lg:w-36 lg:h-36 md:w-28 md:h-28  rounded-2xl object-cover"
                />
              ) : (
                <Jazzicon
                  address={authorData._id}
                  className="w-20 h-20 lg:w-44 lg:h-36 md:w-28 md:h-28  rounded-2xl"
                />
              )
            ) : (
              <Jazzicon
                address={"defaultValue"}
                className="w-20 h-20 lg:w-44 lg:h-36 md:w-28 md:h-28  rounded-2xl"
              />
            )
          ) : (
            <Jazzicon
              address={"defaultValue"}
              className="w-20 h-20 lg:w-44 lg:h-36 md:w-28 md:h-28  rounded-2xl"
            />
          )}
          <div className="flex flex-col justify-start ml-4 w-full lg:space-y-4 md:space-y-3 space-y-2">
            <div className="flex flex-row  w-full space-x-4">
              <p className="md:text-4xl  lg:text-5xl font-extrabold capitalize">
                {authorData ? (authorData.name ? authorData.name : null) : null}
              </p>
              <img src={verified} alt="verified" className="w-6 h-6" />
            </div>
            <Tag label={authorStats.playCount} songCountDisplay={true} />
            <div className="flex flex-row justify-left space-x-8">
              <div className="flex flex-row justify-center space-x-2 items-center">
                <img
                  src={artistProfileAlbum}
                  alt="artistProfileAlbum"
                  className="lg:w-6 md:h-6 w-4 h-4"
                />
                <p className="text-custom-text-gray lg:text-sm md:text-sm text-xs">
                  {authorStats.albumCount} Albums
                </p>
              </div>
              <div className="flex flex-row justify-center space-x-2 items-center">
                <img
                  src={artistProfileSongs}
                  alt="artistProfileSong"
                  className="lg:w-6 md:h-6 w-4 h-4"
                />
                <p className="text-custom-text-gray lg:text-sm md:text-sm text-xs">
                  {authorStats.songCount} Songs
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col lg:justify-between items-center p-4 px-6 bg-custom-white rounded-3xl mt-6 md:w-full md:p-8 lg:w-full lg:p-8 ">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <p className="text-md font-bold">Albums</p>
        </div>
        {albumCards.length > 0 ? (
          <div className="grid grid-cols-2 md:grid-cols-2  lg:grid-cols-5 w-full md:justify-items-center justify-items-center ">
            {albumCards}
          </div>
        ) : (
          <div className="flex flex-row w-full justify-center">
            <p className="text-xl font-bold text-black/50">No Albums</p>
          </div>
        )}
      </div> */}
      <div className="flex flex-col lg:justify-between  items-center p-4 px-6 bg-custom-white rounded-3xl mt-6 md:w-full md:p-8 lg:w-full lg:p-8 ">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <p className="text-md font-bold">Songs</p>
        </div>
        {songCards.length > 0 ? (
          <div className="flex flex-col w-full space-y-2">{songCards}</div>
        ) : (
          <div className="flex flex-col w-full space-y-2 items-center font-bold text-xl text-black/50">
            No Songs
          </div>
        )}
      </div>
    </>
  );
}

export default ArtistProfile;
