import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";

import albumcover from "../../assets/albumcover_sample.png";
import artistProfileAlbum from "../../assets/icons/artistProfileAlbum.svg";
import artistProfileSongs from "../../assets/icons/artistProfileSongs.svg";
import albumearnings from "../../assets/icons/album-earnings.svg";
import verified from "../../assets/icons/verified.svg";
import Tag from "../../components/Tag";
import ArtistCard from "../../components/ArtistCard";
import DetailedSongCard from "../../components/DetailedSongCard";

function SongOverlay(props) {
  const { globalState, setGlobalState } = useContext(AppContext);
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);

  const processCards = async () => {
    console.log("processCards");

    let cards = [];
    let index = 0;
    globalState.songOverlay.contributors.forEach((contributor) => {
      index++;
      if (index !== 1) {
        cards.push(
          <div className="flex flex-row justify-between w-full items-center px-3 rounded-lg py-2 text-xs bg-custom-text-gray/30">
            <p className="text-black w-40">
              {contributor.walletAddress.slice(0, 8) +
                "..." +
                contributor.walletAddress.slice(-8)}
            </p>
            <div className="rounded-lg bg-custom-text-gray px-3 py-1">
              <p className="text-white">{contributor.percentageShare}%</p>
            </div>
            <p className="text-black text-right w-20">
              {(
                (globalState.songOverlay.royaltyValue *
                  parseFloat(contributor.percentageShare)) /
                100
              ).toFixed(2)}
              &nbsp;USD
            </p>
          </div>
        );
      }
      setCards(cards);
      console.log("cards", cards);
    });
  };

  useEffect(() => {
    console.log("SongOverlays", globalState.songOverlay);
    if (Object.keys(globalState.songOverlay).length > 0) {
      console.log("SongOverlay", globalState.songOverlay);
      processCards();
    }
  }, [globalState.songOverlay]);

  return (
    <>
      {/* The button to open modal */}
      {/* <label htmlFor="my-modal-4" className="btn">
        open modal
      </label> */}

      {/* Put this part before </body> tag */}
      <input type="checkbox" id="my-modal-4" className="modal-toggle" />
      <label
        htmlFor="my-modal-4"
        className={
          props.openModal === true
            ? "modal cursor-pointer modal-open"
            : "modal cursor-pointer"
        }
      >
        <div className="bg-custom-white relative p-4 rounded-3xl" htmlFor="">
          {/* <div className="flex flex-col lg:justify-between items-center p-4 px-6 bg-custom-white rounded-3xl mt-2 md:w-full md:p-8 lg:w-full lg:p-2">
              <div className="flex flex-row justify-start w-full items-center lg:items-start md:items-start ">
                <img
                  src={albumcover}
                  alt="albumcover"
                  className="w-20 h-20 lg:w-36 lg:h-36 md:w-28 md:h-28  rounded-2xl"
                />
                <div className="flex flex-col justify-start ml-4 w-full lg:space-y-4 md:space-y-3 space-y-2">
                  <div className="flex flex-row  w-full space-x-4">
                    <p className="md:text-4xl  lg:text-5xl font-extrabold">
                      Nights
                    </p>
                  </div>
                  <Tag label="" songCountDisplay={true} />
                  <div className="flex flex-row justify-left space-x-8">
                    <div className="flex flex-row justify-center space-x-2 items-center">
                      <img
                        src={albumearnings}
                        alt="artistProfileSong"
                        className="lg:w-6 md:h-6 w-4 h-4"
                      />
                      <p className="text-custom-text-gray lg:text-sm md:text-sm text-xs">
                        12,556 USD
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          <p className="py-4 font-bold text-xl">Royalty Distribution</p>
          <div className="flex flex-col justify-between items-center w-full space-y-2">
            <div className="flex flex-row justify-between w-full items-center bg-black px-3 rounded-lg py-2 text-xs">
              <p className="text-white w-40">Author (You)</p>
              <div className="rounded-lg bg-custom-text-gray px-3 py-1">
                <p className="text-white">
                  {globalState.songOverlay?.contributors[0]?.percentageShare}%
                </p>
              </div>
              <p className="text-white text-right w-20">
                {(
                  (globalState.songOverlay.royaltyValue *
                    parseFloat(
                      globalState.songOverlay.contributors[0]?.percentageShare
                    )) /
                  100
                ).toFixed(2)}
                &nbsp;USD
              </p>
            </div>
            {cards}
          </div>
          <div className="flex w-full justify-center mt-4">
            <button
              className="btn bg-custom-primary text-white border-0 rounded-xl capitalize text-md hover:bg-black"
              onClick={() => {
                props.toggleModalFunction();
                setGlobalState((prevState) => {
                  return {
                    ...prevState,
                    songOverlay: {
                      contributors: [
                        {
                          percentageShare: 0,
                        },
                      ],
                      royaltyValue: 0,
                    },
                  };
                });
              }}
            >
              Close
            </button>
          </div>
        </div>
      </label>
    </>
  );
}

export default SongOverlay;
