import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";

import ArtistCard from "./ArtistCard";

function TopArtists(props) {
  const { globalState } = useContext(AppContext);
  const [artistsCards, setArtistsCards] = useState([]);
  const navigate = useNavigate();

  const processArtistsCards = () => {
    console.log("+++",globalState);
    let tempArr = [];
    if (globalState.allAuthors.length > 0) {
      const card = globalState.allAuthors.map((author) => {
        return (
          <ArtistCard
            artistName={author.name}
            artistId={author._id}
            image={author.profileData.profilePicture}
          />
        );
      });
      tempArr.push(card);
      setArtistsCards(tempArr);
    }
  };

  useEffect(() => {
    processArtistsCards();
  }, [globalState.allAuthors]);

  return (
    <div className="flex flex-col items-center p-4 px-6 mt-6 lg:justify-between bg-custom-white rounded-3xl md:w-full md:p-8 lg:w-full lg:p-10">
      <div className="flex flex-row items-center justify-between w-full my-3">
        <p className="font-bold text-md">Charts: Top Artists</p>
        <p
          className="text-sm text-custom-text-gray hover:cursor-pointer"
          onClick={() => navigate("/explore-artists")}
        >
          See All
        </p>
      </div>
      <div className="flex flex-row flex-wrap justify-center w-full my-2 lg:justify-around md:justify-around lg:items-center">
        {artistsCards.length > 0 ? (
          artistsCards
        ) : (
          <p className="text-2xl font-bold text-white/50">No Artists</p>
        )}
      </div>
    </div>
  );
}

export default TopArtists;
