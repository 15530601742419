import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Titlebar from "../../components/Titlebar";
import { AppContext } from "../../AppContext";

import albumcover from "../../assets/albumcover_sample.png";
import artistProfileAlbum from "../../assets/icons/artistProfileAlbum.svg";
import artistProfileSongs from "../../assets/icons/artistProfileSongs.svg";
import albumearnings from "../../assets/icons/album-earnings.svg";
import verified from "../../assets/icons/verified.svg";
import Tag from "../../components/Tag";
import ArtistCard from "../../components/ArtistCard";
import DetailedSongCard from "../../components/DetailedSongCard";
import SongOverlay from "./SongOverlay";

function AlbumViewAuthor(props) {
  const {
    globalState,
    setGlobalState,
    tools,
    checkJWTStatus,
    updateUserObject,
    imageConverter,
    getAlbumsOfAuthor,
  } = useContext(AppContext);
  const { albumName } = useParams();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [albumData, setAlbumData] = useState({
    albumName: "",
    authorName: "",
    albumCover: "",
    albumId: "",
    songs: [],
  });
  const [albumStats, setAlbumStats] = useState({
    totalRoyaltyRate: 0,
    totalPlayCounts: 0,
  });
  const [playlist, setPlaylist] = useState([]);

  const handleModal = () => {
    console.log("handleModal");
    setShowModal(!showModal);
  };

  const initAuthCheck = async () => {
    console.log("[+] Checking if user is authenticated", globalState);
    if (globalState.isAuthenticated === false) {
      console.log("[+] User is not authenticated");
      const status = await checkJWTStatus("Author Profile");
      if (status === false) {
        navigate("/login");
      } else {
        if (globalState.authorAlbumView === "") {
          navigate("/my-albums");
        } else {
          retrieveAlbumData();
        }
      }
    } else {
      if (globalState.authorAlbumView === "") {
        navigate("/my-albums");
      } else {
        retrieveAlbumData();
      }
    }
  };

  function calculateAlbumStats(album) {
    let totalRoyaltyRate = 0;
    let totalPlayCounts = 0;

    album.songs.forEach((song) => {
      const songPlayCounts = parseInt(song.playCounts);
      const songRoyaltyRate = parseFloat(song.rate) * songPlayCounts;

      totalRoyaltyRate += songRoyaltyRate;
      totalPlayCounts += songPlayCounts;

      setAlbumStats({
        totalRoyaltyRate: totalRoyaltyRate,
        totalPlayCounts: totalPlayCounts,
      });
    });
  }

  const retrieveAlbumData = () => {
    console.log("[+] Retrieving Album Data...");
    let songUrls = [];
    let album = globalState.albums.find(
      (album) => album.albumId === globalState.authorAlbumView
    );
    console.log("retrieveAlbumData: ", album);
    setAlbumData(album);
    calculateAlbumStats(album);

    album.songs.forEach((song) => {
      songUrls.push(song.song);
      setPlaylist(songUrls);
      console.log("songUrls: ", songUrls);
    });
  };

  useEffect(() => {
    console.log("AlbumViewAuthor: ", globalState.authorAlbumView, globalState);
    initAuthCheck();
  }, []);

  useEffect(() => {
    console.log(
      "[+] AlbumViewAuthor: ",
      globalState.authorAlbumView,
      globalState
    );
  }, [globalState.authorAlbumView]);

  return (
    <>
      <SongOverlay openModal={showModal} toggleModalFunction={handleModal} />
      <Titlebar showIcon={true} pageTitle={"Your Albums"} showSearchBar={false}/>
      <div className="flex flex-col lg:justify-between items-center p-4 px-6 bg-custom-white rounded-3xl mt-6 md:w-full md:p-8 lg:w-full lg:p-8">
        <div className="flex flex-row justify-start w-full items-center lg:items-start md:items-start ">
          {/* {globalState.authorAlbumView === "" ? null : ( */}
          {albumData.albumName === "" ? null : (
            <img
              src={imageConverter(albumData.albumCover)}
              alt="albumcover"
              className="w-20 h-20 lg:w-36 lg:h-36 md:w-28 md:h-28  rounded-2xl"
            />
          )}
          <div className="flex flex-col justify-start ml-4 w-full lg:space-y-4 md:space-y-3 space-y-2">
            <div className="flex flex-row  w-full space-x-4">
              <p className="md:text-4xl  lg:text-5xl font-extrabold">
                {albumData.albumName}
              </p>
            </div>
            <Tag label={albumStats.totalPlayCounts} songCountDisplay={true} />
            <div className="flex flex-row justify-left space-x-8">
              <div className="flex flex-row justify-center space-x-2 items-center">
                <img
                  src={artistProfileSongs}
                  alt="artistProfileSong"
                  className="lg:w-6 md:h-6 w-4 h-4"
                />
                <p className="text-custom-text-gray lg:text-sm md:text-sm text-xs">
                  {albumData.songs.length} &nbsp;Song(s)
                </p>
              </div>
              <div className="flex flex-row justify-center space-x-2 items-center">
                <img
                  src={albumearnings}
                  alt="artistProfileSong"
                  className="lg:w-6 md:h-6 w-4 h-4"
                />
                <p className="text-custom-text-gray lg:text-sm md:text-sm text-xs">
                  {parseFloat(albumStats.totalRoyaltyRate).toFixed(2)} USD
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:justify-between items-center p-4 px-6 bg-custom-white rounded-3xl mt-6 md:w-full md:p-8 lg:w-full lg:p-8 ">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <p className="text-md font-bold">Songs</p>
        </div>
        <div className="flex flex-col w-full items-center">
          {albumData.songs.length > 0 ? (
            albumData.songs.map((song) => (
              <DetailedSongCard
                key={song.songId}
                songName={song.songName}
                songImage={song.songCover}
                showPlayCount={true}
                showReleased={true}
                showRoyalty={true}
                toggleModalFunction={handleModal}
                authorName={albumData.authorName}
                royaltyValue={(
                  parseFloat(song.rate) * parseInt(song.playCounts)
                ).toFixed(2)}
                playCounts={song.playCounts}
                releaseDate={song.timestamp}
                songId={song.songId}
                songUrl={song.song}
                showDistribution={true}
                showRoyaltyPercentage={true}
                royaltyPercentage={song.contributors[0].percentageShare}
                playlist={playlist}
                contributors={song.contributors}
                royaltyValueNoFixed={
                  parseFloat(song.rate) * parseInt(song.playCounts)
                }
              />
            ))
          ) : (
            <p className="text-3xl text-black/30  font-bold">No Songs Found</p>
          )}
        </div>
      </div>
    </>
  );
}

export default AlbumViewAuthor;
