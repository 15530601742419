import React from "react";

function ButtonOne(props) {
  return (
    <button
      className={
        props.alert === true ? "btn bg-custom-red text-white border-0 rounded-xl capitalize text-md hover:bg-black" : "btn bg-custom-primary text-white border-0 rounded-xl capitalize text-md hover:bg-black"
      }
      onClick={() => props.onClick()}
    >
      {props.label}
    </button>
  );
}

export default ButtonOne;
