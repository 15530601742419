import React, { useState } from "react";

function RoleSelector(props) {
  const [role, setRole] = useState("listener");

  return (
    <div className="flex flex-col md:flex-row">
      <button
        className={
          role === "author"
            ? "w-full md:w-auto md:mx-2 px-6 py-1 font-bold bg-custom-primary rounded-2xl text-white"
            : "w-full md:w-auto md:mx-2 px-6 py-1 font-bold text-black/40"
        }
        onClick={() => {
          setRole("author");
          props.setRole("author");
        }}
      >
        Author
      </button>
      <button
        className={
          role === "listener"
            ? "w-full md:w-auto md:mx-2 px-6 py-1 font-bold bg-custom-primary rounded-2xl text-white"
            : "w-full md:w-auto md:mx-2 px-6 py-1 font-bold text-black/40"
        }
        onClick={() => {
          setRole("listener");
          props.setRole("listener");
        }}
      >
        Listener
      </button>
      <button
        className={
          role === "contributor"
            ? "w-full md:w-auto md:mx-2 px-6 py-1 font-bold bg-custom-primary rounded-2xl text-white"
            : "w-full md:w-auto md:mx-2 px-6 py-1 font-bold text-black/40"
        }
        onClick={() => {
          setRole("contributor");
          props.setRole("contributor");
        }}
      >
        Contributor
      </button>
    </div>
  );
}

export default RoleSelector;
