import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";

import ArtistCard from "./ArtistCard";

function TopAlbums(props) {
  const { globalState } = useContext(AppContext);
  const [artistsCards, setArtistsCards] = useState([]);
  const navigate = useNavigate();

  const processArtistsCards = () => {
    let tempArr = [];
    if (globalState.allAuthors.length > 0) {
      const card = globalState.allAuthors.map((author) => {
        return <ArtistCard artistName={author.name} artistId={author._id} />;
      });
      tempArr.push(card);
      setArtistsCards(tempArr);
    }
  };

  useEffect(() => {
    processArtistsCards();
  }, [globalState.allAuthors]);

  return (
    <div className="flex flex-col lg:justify-between items-center p-4 px-6 bg-custom-white rounded-3xl mt-6 md:w-full md:p-8 lg:w-full lg:p-10">
      <div className="flex flex-row justify-between w-full items-center my-3">
        <p className="text-md font-bold">Charts: Top Albums</p>
        <p
          className="text-custom-text-gray text-sm hover:cursor-pointer"
          onClick={() => navigate("/top-artists")}
        >
          See All
        </p>
      </div>
      <div className="flex flex-row lg:justify-around md:justify-around w-full justify-center  lg:items-center flex-wrap my-2">
        {artistsCards.length > 0 ? (
          artistsCards
        ) : (
          <p className="text-2xl font-bold text-white/50">No Artists</p>
        )}
      </div>
    </div>
  );
}

export default TopAlbums;
