import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import ListenerDashboard from "./listener/ListenerDashboard";
import AuthorDashboard from "./author/AuthorDashboard";
import ContributorDashboard from "./contributor/ContributorDashboard";

function Dashboard(props) {
  const { globalState, setGlobalState, tools, checkJWTStatus } =
    useContext(AppContext);
  const navigate = useNavigate();

  const initAuthCheck = async () => {
    console.log("[+] Checking if user is authenticated", globalState);
    if (globalState.isAuthenticated === false) {
      console.log("[+] User is not authenticated");
      const status = await checkJWTStatus("Dashboard");
      if (status === false) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    initAuthCheck();
  }, []);

  return (
    <>
      {globalState.isAuthenticated === true ? (
        globalState.userObject.role === "author" ? (
          <AuthorDashboard />
        ) : globalState.userObject.role === "contributor" ? (
          <ContributorDashboard />
        ) : (
          <ListenerDashboard />
        )
      ) : null}
    </>
  );
}

export default Dashboard;
