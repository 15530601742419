import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AppContext } from "../../AppContext";

import Titlebar from "../../components/Titlebar";
import artistProfileSongs from "../../assets/icons/artistProfileSongs.svg";
import artistProfileAlbum from "../../assets/icons/artistProfileAlbum.svg";
import calendarIcon from "../../assets/icons/calendar.svg";
import Tag from "../../components/Tag";
import AddressDisplay from "../../components/AddressDisplay";

import InputOne from "../../components/InputOne";
import ButtonOne from "../../components/ButtonOne";

function ContributorProfile(props) {
  const navigate = useNavigate();
  const {
    globalState,
    setGlobalState,
    tools,
    checkJWTStatus,
    connectWallet,
    updateUserObject,
  } = useContext(AppContext);

  const initAuthCheck = async () => {
    console.log("[+] Checking if user is authenticated", globalState);
    if (globalState.isAuthenticated === false) {
      console.log("[+] User is not authenticated");
      const status = await checkJWTStatus("Author Dashoboard");
      if (status === false) {
        navigate("/login");
      } else {
        // getAlbumsOfAuthor(globalState.userObject.id);
      }
    } else {
      // getAlbumsOfAuthor(globalState.userObject.id);
    }
  };

  useEffect(() => {
    initAuthCheck();
  }, []);
  return (
    <>
      <Titlebar
        showIcon={false}
        showSearchBar={false}
        pageTitle={"Your Profile"}
      />
      <div className="flex flex-col lg:justify-between items-center p-4 px-6 bg-custom-white rounded-3xl mt-6 md:w-full md:p-8 lg:w-full lg:p-8">
        <div className="flex flex-row justify-start w-full items-center lg:items-start md:items-start ">
          <div className="flex flex-col justify-start ml-4 w-full lg:space-y-4 md:space-y-3 space-y-2">
            <div className="flex flex-row  w-full space-x-4">
              <p className="md:text-4xl  lg:text-5xl font-extrabold">
                {globalState.userObject.name}
              </p>
            </div>
            <Tag label={globalState.userObject.role} songCountDisplay={false} />
            <div className="flex lg:flex-row flex-col  lg:justify-between items-start  lg:space-x-8 space-y-4 lg:pt-0 pt-2">
              <div className="flex flex-row space-x-8">
                {/* <div className="flex flex-row justify-center space-x-2 items-center">
                  <img
                    src={calendarIcon}
                    alt="joinDate"
                    className="lg:w-6 md:h-6 w-4 h-4"
                  />
                  <p className="text-custom-text-gray lg:text-sm md:text-sm text-xs">
                    17 Apr, 2023
                  </p>
                </div> */}
                <div className="flex flex-row justify-center space-x-2 items-center">
                  <img
                    src={artistProfileSongs}
                    alt="artistProfileSong"
                    className="lg:w-6 md:h-6 w-4 h-4"
                  />
                  <p className="text-custom-text-gray lg:text-sm md:text-sm text-xs">
                    {globalState.contributorData.totalSongs
                      ? globalState.contributorData.totalSongs
                      : 0} Songs
                  </p>
                </div>
              </div>
              <AddressDisplay />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col p-8 rounded-3xl bg-custom-white mt-6">
        <p className="text-md font-bold mb-6">Top Songs</p>
        <div className="flex flex-row w-full justify-between">
          <div className="flex flex-col space-y-4 w-fit">
            {/* <InputOne
              placeholder="Enter Name"
              onInput={(e) => setConfirmPassword(e.target.value)}
              // value={confirmPassword}
              name="confirmPassword"
            />
            <InputOne
              placeholder="Enter Email"
              onInput={(e) => setConfirmPassword(e.target.value)}
              // value={confirmPassword}
              name="confirmPassword"
            />
            <InputOne
              placeholder="Enter New Password"
              onInput={(e) => setConfirmPassword(e.target.value)}
              // value={confirmPassword}
              name="confirmPassword"
            /> */}
          </div>
          <div className="flex flex-col items-center space-y-4">
            <p className="font-bold">
              {globalState.userObject.walletAddress?.length > 0 ||
              globalState.account?.length > 0
                ? globalState.userObject.walletAddress.length > 0
                  ? globalState.userObject.walletAddress.substring(0, 6) +
                    "..." +
                    globalState.userObject.walletAddress.substring(
                      globalState.userObject.walletAddress.length - 4,
                      globalState.userObject.walletAddress.length
                    )
                  : globalState.account.substring(0, 6) +
                    "..." +
                    globalState.account.substring(
                      globalState.account.length - 4,
                      globalState.account.length
                    )
                : "Please connect your wallet"}
            </p>
            {globalState.userObject.walletAddress?.length === 0 ? (
              globalState.account.length === 0 ? (
                <button
                  className="btn bg-custom-primary text-white border-0 rounded-xl capitalize text-md hover:bg-black w-80"
                  onClick={async () => {
                    await connectWallet();
                  }}
                >
                  Connect Wallet
                </button>
              ) : (
                <button
                  className="btn bg-custom-primary/50 text-white/50 border-0 rounded-xl capitalize text-md hover:bg-custom-primary/50 w-80 disabled cursor-not-allowed"
                  onClick={() => {
                    connectWallet();
                  }}
                >
                  Wallet Connected
                </button>
              )
            ) : (
              <>
                <button
                  className="btn bg-custom-primary/50 text-white/50 border-0 rounded-xl capitalize text-md hover:bg-custom-primary/50 w-80 disabled cursor-not-allowed"
                  onClick={() => {
                    connectWallet();
                  }}
                >
                  Wallet Connected
                </button>
              </>
            )}
          </div>
          {/* <div className="flex flex-col items-center space-y-4">
            <p className="font-bold">0x156..6513</p>
            <button
              className="btn bg-custom-primary text-white border-0 rounded-xl capitalize text-md hover:bg-black w-80"
              // onClick={() => connectWallet()}
            >
              Connect Wallet
            </button>
            <button
              className="btn bg-custom-primary text-white border-0 rounded-xl capitalize text-md hover:bg-black w-80"
              // onClick={() => connectWallet()}
            >
              Save Wallet
            </button>
          </div> */}
        </div>

        <div className="flex flex-row justify-between w-full mt-8">
          <ButtonOne
            label="Save Changes"
            onClick={() => {
              if (
                globalState.userObject.walletAddress.length === 0 &&
                globalState.account.length > 0
              ) {
                console.log(
                  "updating wallet",
                  globalState.account,
                  globalState
                );
                let tempUserObject = globalState.userObject;
                console.log("[1] tempUserObject: ", tempUserObject);
                tempUserObject.walletAddress = globalState.account;
                console.log("[2] tempUserObject: ", tempUserObject);
                updateUserObject(tempUserObject);
              } else {
                toast.error("No Changes");
              }
            }}
          />
          <ButtonOne
            alert={true}
            label="Delete Account"
            // onClick={() => connectWallet()}
          />
        </div>
      </div>
    </>
  );
}

export default ContributorProfile;
