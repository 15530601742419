import { AppContext } from "./AppContext";
import { AppProvider } from "./AppContext.js";
import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import "./App.css";
import Homepage from "./pages/Homepage";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Dashboard from "./pages/Dashboard";
import LikedSongs from "./pages/listener/LikedSongs";
import TopArtists from "./pages/listener/TopArtists";
import ArtistProfile from "./pages/listener/ArtistProfile";
import AlbumView from "./pages/listener/AlbumView";
import UserProfile from "./pages/listener/UserProfile";
import TopSongs from "./pages/listener/TopSongs";
import AlbumViewAuthor from "./pages/author/AlbumViewAuthor";
import AuthorProfile from "./pages/author/AuthorProfile";

import CreateAlbum from "./pages/author/CreateAlbum";
import MyAlbums from "./pages/author/MyAlbums";
import ContributorProfile from "./pages/contributor/ContributorProfile";
import AddSong from "./pages/author/AddSong";
import ContributorDashboard from "./pages/contributor/ContributorDashboard";

function App() {
  return (
    <AppProvider>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/liked-songs" element={<LikedSongs />} />
        <Route path="/explore-songs" element={<TopSongs />} />
        <Route path="/explore-artists" element={<TopArtists />} />
        <Route path="/artists/:artistName" element={<ArtistProfile />} />
        <Route path="/albums/:albumName" element={<AlbumView />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/create-album" element={<CreateAlbum />} />
        <Route path="/my-albums" element={<MyAlbums />} />
        <Route path="/view-album" element={<AlbumViewAuthor />} />
        <Route path="/author-profile" element={<AuthorProfile />} />
        <Route path="/contributor-profile" element={<ContributorProfile />} />
        <Route path="/add-song" element={<AddSong />} />
        <Route
          path="/contributor-dashboard"
          element={<ContributorDashboard />}
        />
      </Routes>
    </AppProvider>
  );
}

export default App;
