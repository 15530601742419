import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { useNavigate } from "react-router-dom";
import { Jazzicon } from "@ukstv/jazzicon-react";
import { toast } from "react-hot-toast";

import Titlebar from "../../components/Titlebar";
import InputOne from "../../components/InputOne";
import ButtonOne from "../../components/ButtonOne";

import uploadIcon from "../../assets/icons/uploadIcon.svg";

function CreateAlbum(props) {
  const {
    globalState,
    setGlobalState,
    checkJWTStatus,
    updateUserObject,
    imageConverter,
    createAlbum,
    uploadFileToS3
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [albumName, setAlbumName] = useState("");

  const handleImageChange = (e) => {
    console.log("Image Change: ", e.target.files);
    const file = e.target.files[0];
    setImage(file);
  };

  const initAuthCheck = async () => {
    console.log("[+] Checking if user is authenticated", globalState);
    if (globalState.isAuthenticated === false) {
      console.log("[+] User is not authenticated");
      const status = await checkJWTStatus("Author Dashoboard");
      if (status === false) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    initAuthCheck();
  }, []);
  return (
    <>
      <Titlebar
        showIcon={false}
        showSearchBar={false}
        pageTitle={"Create New Album"}
      />
      <div className="flex flex-col p-8 rounded-3xl bg-custom-white mt-6">
        <p className="text-md font-bold mb-6">Album Metadata</p>
        <div className="w-fit">
          <InputOne
            label="Album Name"
            placeholder="Album Name"
            onInput={(e) => {
              console.log(e.target.value);
              setAlbumName(e.target.value);
            }}
            value={albumName}
          />
          <div
            className={
              image !== null
                ? "relative flex flex-col justify-center rounded-3xl bg-dark-green w-fit py-12 mt-4"
                : "relative flex flex-col justify-center rounded-3xl bg-custom-text-gray w-fit py-12 mt-4"
            }
          >
            <input
              //   ref={fileInputRef}
              type="file"
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
              onInput={(e) => {
                console.log("image changed");
                handleImageChange(e);
              }}
            />
            <img
              src={uploadIcon}
              alt="uploadIcon"
              className="w-12 h-12 mx-auto"
            />
            <p className="text-sm font-bold text-custom-white p-2 hover:cursor-pointer w-44 text-center">
              {image !== null ? "Uploaded" : "Upload Album Cover"}
            </p>
          </div>
        </div>
        <div className="mt-4">
          <ButtonOne
            label="Create Album"
            className=""
            onClick={async () => {
              console.log("Create Album Clicked");
              if (image !== null && albumName !== "") {
                const resourceUrl = await uploadFileToS3(image, "images/albumcovers");
                createAlbum(albumName, resourceUrl);
                setImage(null);
                setAlbumName("");
              } else {
                toast.error("Please fill all the fields");
              }
            }}
          />
        </div>
      </div>
    </>
  );
}

export default CreateAlbum;
