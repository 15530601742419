import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Web3 from "web3";
import axios from "axios";

import Loading from "./components/Loading";
import Navbar from "./pages/Navbar";
import contractabi from "./smart-contract/contractABI.js";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const backendURL = "https://api.ropplatform.com";
  // const backendURL = "http://localhost:4000";

  const [globalState, setGlobalState] = useState({
    isAuthenticated: false,
    isWalletLogin: false,
    isEmailLogin: false,
    isCacheLogin: false,
    web3: null,
    access_token: "",
    walletConnected: false,
    account: "",
    userObject: {},
    authorAlbumView: "",
    loading: {
      status: false,
      message: "",
      error: false,
    },
    albums: [],
    songCount: 0,
    playCount: 0,
    royalty: 0,
    contractAddress: "0x686F47d9c9630FeD17b2306124FfF7FA95E2D1A1",
    onChainSongData: {},
    totalSongCount: 0,
    totalPlayCount: 0,
    totalRoyalty: 0,
    albumPlayCount: 0,
    albumRoyalty: 0,
    liveSong: {
      song: {
        name: "",
        artist: "",
        url: "",
        cover: "",
        id: "",
      },
      playlist: [],
    },
    allAlbums: [],
    allAuthors: [],
    likedSongs: [],
    songOverlay: {
      contributors: [
        {
          percentageShare: 0,
        },
      ],
      royaltyValue: 0,
    },
    stats: {
      countsSum: 0,
      totalSongs: 0,
      royaltySum: 0,
      playCountsSum: 0,
    },
    contributorData: {
      counts: 0,
      totalSongs: 0
    }
  });

  const [tools, setTools] = useState({
    navigate: useNavigate(),
  });

  const checkJWTStatus = async (page) => {
    console.log("Checking JWT Status from:: ", page);
    const refresh_token = localStorage.getItem("refresh_token");
    const access_token = localStorage.getItem("access_token");
    if (refresh_token && access_token) {
      console.log("Refresh Token exists", refresh_token.trim(0, 10));
      const validityResponse = await checkIfValidToken(
        refresh_token,
        "refresh"
      );
      if (validityResponse.status === true) {
        console.log("Token is valid. Retrieving user object...");
        const userObjectResponse = await getUserById(
          validityResponse.data.user,
          access_token
        );
        if (userObjectResponse.status === true) {
          setGlobalState((prevState) => ({
            ...prevState,
            access_token: access_token,
            isAuthenticated: true,
            userObject: userObjectResponse.data,
            isCacheLogin: true,
          }));
          return true;
        } else {
          console.log("Error occurred while retrieving user object");
          setGlobalState((prevState) => ({
            ...prevState,
            access_token: "",
            isAuthenticated: false,
          }));
          clearLocalStorage();
          return false;
        }
      } else {
        console.log("Token present. But invalid token.");
        setGlobalState((prevState) => ({
          ...prevState,
          access_token: "",
          isAuthenticated: false,
        }));
        clearLocalStorage();
        return false;
      }
    } else {
      console.log("Refresh Token does not exist");
      setGlobalState((prevState) => ({
        ...prevState,
        access_token: "",
        isAuthenticated: false,
      }));
      clearLocalStorage();
      return false;
    }
  };

  const checkIfValidToken = async (token, type) => {
    console.log("Checking if token is valid...");
    try {
      const response = await axios.post(`${backendURL}/v1/auth/verify-token`, {
        token: token,
        type: type,
      });
      console.log("Token validity response: ", response.data);
      const returnObject = { status: true, data: response.data };
      return returnObject;
    } catch (error) {
      console.log("Error occurred while checking token validity: ", error);
      const returnObject = { status: false, data: error };
      return returnObject;
    }
  };

  const getUserById = async (id, token) => {
    console.log("Getting user by id: ", id);
    try {
      const response = await axios.get(`${backendURL}/v1/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("User Object response: ", response.data);
      const returnObject = { status: true, data: response.data };
      console.log("NewLikedSongs 1: ", returnObject);
      const newLikedSongs = returnObject.data.likedSongs.map((obj) =>
        Object.values(obj).join("")
      );
      console.log("NewLikedSongs 2: ", newLikedSongs);
      returnObject.data.likedSongs = newLikedSongs;
      console.log("NewLikedSongs 3: ", returnObject);
      return returnObject; // Include the return statement here
    } catch (error) {
      console.log("Error occurred while retrieving user object: ", error);
      const returnObject = { status: false, data: error };
      return returnObject;
    }
  };

  const connectWallet = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const web3 = new Web3(window.ethereum);
        setGlobalState((prevState) => {
          return { ...prevState, web3: web3 };
        });

        console.log("<< Web3 Object Received  >>");

        window.ethereum
          .request({ method: "net_version" })
          .then(async (chainId) => {
            if (chainId !== "80001") {
              try {
                await window.ethereum.request({
                  method: "wallet_switchEthereumChain",
                  params: [{ chainId: "0x13881" }],
                });
                console.log("Polygon Mumbai Chain found.");
              } catch (switchError) {
                console.log(
                  "[W3] Error connecting to Polygon Mumbai Chain.",
                  error
                );
                if (switchError.code === 4902) {
                  try {
                    await ethereum.request({
                      method: "wallet_addEthereumChain",
                      params: [
                        {
                          chainId: "0x13881",
                          chainName: "Polygon Mumbai Testnet",
                          rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
                        },
                      ],
                    });
                  } catch (addError) {
                    console.log(
                      "[W4] Error adding Polygon Mumbai Chain. Check RPC URL.",
                      error
                    );
                    toast.error(
                      "[W4] Error adding Polygon Mumbai Chain. Please add it manually."
                    );
                  }
                }
              }
            }
          });

        const accounts = await web3.eth.getAccounts();
        console.log("<< Account Received  >>", accounts[0]);
        toast.success("Wallet Connected");
        setGlobalState((prevState) => {
          return {
            ...prevState,
            account: accounts[0],
            walletConnected: true,
          };
        });
      } catch (error) {
        console.error(error);
        console.log("[W2] Error connecting to Metamask. Refresh page.");
      }
    } else {
      console.log("Please install MetaMask to connect your wallet.");
      toast.error("[W1] Please install MetaMask to connect your wallet.");
    }
  };

  const disconnectWallet = () => {
    setGlobalState((prevState) => {
      return {
        ...prevState,
        walletConnected: false,
        account: "",
      };
    });
    toast.success("Wallet Disconnected");
  };

  const setLocalStorage = (
    accessToken,
    accessExpiry,
    refreshToken,
    refreshExpiry,
    id,
    role,
    name,
    walletAddress
  ) => {
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("access_expiry", accessExpiry);
    localStorage.setItem("refresh_token", refreshToken);
    localStorage.setItem("refresh_expiry", refreshExpiry);
    localStorage.setItem("user_id", id);
    localStorage.setItem("user_role", role);
    localStorage.setItem("user_name", name);
    localStorage.setItem("user_wallet", walletAddress);
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("access_expiry");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("refresh_expiry");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_role");
    localStorage.removeItem("user_name");
    localStorage.removeItem("user_wallet");
  };

  const emailSignup = (name, email, password, role) => {
    setGlobalState((prevState) => {
      return {
        ...prevState,
        loading: {
          status: true,
          message: "Signing up New User...",
          error: false,
        },
      };
    });
    console.log("<< Email Signup >>", name, email, password);
    axios
      .post(`${backendURL}/v1/auth/register`, {
        name: name,
        email: email,
        password: password,
        role: role,
        profileData: {
          name: name,
          email: email,
          role: role,
          date: Date.now(),
        },
      })
      .then((response) => {
        console.log("[Response] Email Signup: ", response.data);
        setLocalStorage(
          response.data.tokens.access.token,
          response.data.tokens.access.expires,
          response.data.tokens.refresh.token,
          response.data.tokens.refresh.expires,
          response.data.user.id,
          response.data.user.role,
          response.data.user.name,
          ""
        );
        toast.success("Signup Successful. Please Login.");
        setGlobalState((prevState) => {
          return {
            ...prevState,
            loading: {
              status: false,
              message: "",
              error: false,
            },
          };
        });
        tools.navigate("/login");
      })
      .catch((error) => {
        setGlobalState((prevState) => {
          return {
            ...prevState,
            loading: {
              status: false,
              message: "",
              error: false,
            },
          };
        });
        console.log("[Error] Email Signup: ", error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  const encodeWalletAddress = (address) => {
    // Remove the first two characters "0x"
    address = address.slice(2);

    // Convert each hexadecimal digit to a decimal number
    const decimalArray = [];
    for (let i = 0; i < address.length; i += 2) {
      decimalArray.push(parseInt(address.slice(i, i + 2), 16));
    }

    // Apply the custom algorithm to each decimal number
    const encodedArray = decimalArray.map((decimal) => {
      return ((decimal * 7) % 23) + 65;
    });

    // Convert the encoded decimal array to a string
    const encodedString = String.fromCharCode(...encodedArray);

    console.log("<< Encoded Wallet Address >>", encodedString);

    return encodedString;
  };

  const getSignature = async () => {
    const encodedAddress = encodeWalletAddress(globalState.account.toString());
    const message =
      "Welcome to Raindrops On Petals Music Platform. Please sign this message to verify your wallet address. " +
      encodedAddress;
    const signature = await globalState.web3.eth.personal.sign(
      message,
      globalState.account
    );
    return signature;
  };

  const walletSignup = async (name, role) => {
    setGlobalState((prevState) => {
      return {
        ...prevState,
        loading: {
          status: true,
          message: "Signing up New User...",
          error: false,
        },
      };
    });
    let sign = "";
    try {
      sign = await getSignature();
      console.log("<< Wallet Signature Received >>", sign);
    } catch (error) {
      console.log("[W3] Error getting signature from wallet.", error);
      toast.error("[W3] Error getting signature from wallet.");
      setGlobalState((prevState) => {
        return {
          ...prevState,
          loading: {
            status: false,
            message: "",
            error: false,
          },
        };
      });
      return;
    }
    console.log("<< Wallet Signup >>", name, globalState.account);
    axios
      .post(`${backendURL}/v1/auth/register`, {
        name: name,
        email: globalState.account,
        password: sign,
        walletAddress: globalState.account,
        role: role,
      })
      .then((response) => {
        console.log("[Response] Wallet Signup: ", response.data);
        setLocalStorage(
          response.data.tokens.access.token,
          response.data.tokens.access.expires,
          response.data.tokens.refresh.token,
          response.data.tokens.refresh.expires,
          response.data.user.id,
          response.data.user.role,
          response.data.user.name,
          response.data.user.walletAddress
        );
        toast.success("Signup Successful. Please Login.");
        setGlobalState((prevState) => {
          return {
            ...prevState,
            loading: {
              status: false,
              message: "",
              error: false,
            },
          };
        });
        tools.navigate("/login");
      })
      .catch((error) => {
        setGlobalState((prevState) => {
          return {
            ...prevState,
            loading: {
              status: false,
              message: "",
              error: false,
            },
          };
        });
        console.log("[Error] Wallet Signup: ", error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  const emailLogin = (email, password) => {
    console.log("<< Email Login >>", email, password);
    setGlobalState((prevState) => {
      return {
        ...prevState,
        loading: {
          status: true,
          message: "Logging in...",
          error: false,
        },
      };
    });

    axios
      .post(`${backendURL}/v1/auth/login`, {
        email: email,
        password: password,
      })
      .then((response) => {
        console.log("[Response] Email Login: ", response.data);
        setLocalStorage(
          response.data.tokens.access.token,
          response.data.tokens.access.expires,
          response.data.tokens.refresh.token,
          response.data.tokens.refresh.expires,
          response.data.user.id,
          response.data.user.role,
          response.data.user.name,
          response.data.user.walletAddress
        );
        setGlobalState((prevState) => {
          return {
            ...prevState,
            userObject: response.data.user,
          };
        });
        toast.success("Login Successful.");
        setGlobalState((prevState) => {
          return {
            ...prevState,
            isAuthenticated: true,
            isEmailLogin: true,
            loading: {
              status: false,
              message: "",
              error: false,
            },
          };
        });
        tools.navigate("/dashboard");
      })
      .catch((error) => {
        setGlobalState((prevState) => {
          return {
            ...prevState,
            loading: {
              status: false,
              message: "",
              error: false,
            },
          };
        });
        console.log("[Error] Email Login: ", error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  const walletLogin = async (address) => {
    console.log("<< Wallet Login >>", address);
    setGlobalState((prevState) => {
      return {
        ...prevState,
        loading: {
          status: true,
          message: "Logging in...",
          error: false,
        },
      };
    });
    let sign = "";
    try {
      sign = await getSignature();
      console.log("<< Wallet Signature Received >>", sign);
    } catch (error) {
      console.log("[W3] Error getting signature from wallet.", error);
      toast.error("[W3] Error getting signature from wallet.");
      setGlobalState((prevState) => {
        return {
          ...prevState,
          loading: {
            status: false,
            message: "",
            error: false,
          },
        };
      });
      return;
    }
    axios
      .post(`${backendURL}/v1/auth/login`, {
        email: address,
        password: sign,
      })
      .then((response) => {
        console.log("[Response] Wallet Login: ", response.data);
        setLocalStorage(
          response.data.tokens.access.token,
          response.data.tokens.access.expires,
          response.data.tokens.refresh.token,
          response.data.tokens.refresh.expires,
          response.data.user.id,
          response.data.user.role,
          response.data.user.name,
          response.data.user.walletAddress
        );
        setGlobalState((prevState) => {
          return {
            ...prevState,
            userObject: response.data.user,
          };
        });
        toast.success("Login Successful.");
        setGlobalState((prevState) => {
          return {
            ...prevState,
            isAuthenticated: true,
            isWalletLogin: true,
            loading: {
              status: false,
              message: "",
              error: false,
            },
          };
        });
        tools.navigate("/");
      })
      .catch((error) => {
        setGlobalState((prevState) => {
          return {
            ...prevState,
            loading: {
              status: false,
              message: "",
              error: false,
            },
          };
        });
        console.log("[Error] Wallet Login: ", error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  const logout = () => {
    console.log("<< Logout >>");
    clearLocalStorage();
    setGlobalState((prevState) => {
      return {
        ...prevState,
        isAuthenticated: false,
        isEmailLogin: false,
        isWalletLogin: false,
        userObject: {},
      };
    });
    tools.navigate("/login");
  };

  const updateUserObject = (userObject) => {
    setGlobalState((prevState) => {
      return {
        ...prevState,
        loading: {
          status: true,
          message: "Updating Data...",
          error: false,
        },
      };
    });

    console.log("<< Update User Object >>", userObject);
    axios
      .post(`${backendURL}/update-user`, {
        userObject: userObject,
        userId: globalState.userObject.id,
      })
      .then((response) => {
        console.log("[Response] Update User Object: ", response.data);
        toast.success("Data Updated Successfully.");
        getUserById(globalState.userObject.id, globalState.access_token);
        setGlobalState((prevState) => {
          return {
            ...prevState,
            userObject: userObject,
            loading: {
              status: false,
              message: "",
              error: false,
            },
          };
        });
      })
      .catch((error) => {
        console.log("[Error] Update User Object: ", error);
        toast.error("Error Updating Data.");
        setGlobalState((prevState) => {
          return {
            ...prevState,
            loading: {
              status: false,
              message: "",
              error: false,
            },
          };
        });
      });
  };

  const createAlbum = (name, image) => {
    setGlobalState((prevState) => {
      return {
        ...prevState,
        loading: {
          status: true,
          message: "Creating New Album...",
          error: false,
        },
      };
    });

    console.log("<< Create New Album >>");
    axios
      .post(`${backendURL}/create-album`, {
        albumName: name,
        albumCover: image,
        albumAuthor: globalState.userObject.id,
        authorName: globalState.userObject.name,
      })
      .then((response) => {
        console.log("[Response] Created New Album: ", response.data);
        toast.success("Album Created Successfully.");
        setGlobalState((prevState) => {
          return {
            ...prevState,
            loading: {
              status: false,
              message: "",
              error: false,
            },
          };
        });
      })
      .catch((error) => {
        console.log("[Error] Creating New Album: ", error);
        toast.error("Error Creating Album.");
        setGlobalState((prevState) => {
          return {
            ...prevState,
            loading: {
              status: false,
              message: "",
              error: false,
            },
          };
        });
      });
  };

  const getAlbumsOfAuthor = async (author) => {
    console.log("<< Get Albums of Author >>");
    axios
      .post(`${backendURL}/calculate-stats`, {
        authorId: author,
      })
      .then((response) => {
        console.log("[Response] Calculate Stats: ", response.data);
        setGlobalState((prevState) => {
          return {
            ...prevState,
            stats: response.data,
          };
        });
      })
      .catch((error) => {
        console.log("[Error] Calculate Stats: ", error);
      });
    axios
      .get(`${backendURL}/get-albums`, {
        params: {
          authorId: author,
        },
      })
      .then((response) => {
        console.log("[Response] Get Albums of Author: ", response.data);
        setGlobalState((prevState) => {
          return {
            ...prevState,
            albums: response.data,
          };
        });
        getOnChainSongData(response.data).then((response) => {
          // console.log(
          //   "[Response] Get On-Chain Song Data: ",
          //   globalState.onChainSongData
          // );
        });
      })
      .catch((error) => {
        console.log("[Error] Creating New Album: ", error);
      });
  };

  const imageConverter = (image) => {
    // const imageData = JSON.parse(image);
    // const imageArray = Object.values(imageData);

    // // Create a Blob from the Uint8Array
    // const blob = new Blob([new Uint8Array(imageArray)]);

    // // Generate a URL for the Blob
    // const imageUrl = URL.createObjectURL(blob);

    // return imageUrl;
    return image;
  };

  function arrayBufferToString(arrayBuffer) {
    return new Promise((resolve, reject) => {
      const textDecoder = new TextDecoder("utf-8");
      const decodedString = textDecoder.decode(arrayBuffer);
      resolve(decodedString);
    });
  }

  function stringToArrayBuffer(str) {
    return new Promise((resolve, reject) => {
      const textEncoder = new TextEncoder();
      const encodedData = textEncoder.encode(str);
      resolve(encodedData.buffer);
    });
  }

  const addNewSong = async (
    songName,
    song,
    cover,
    contributors,
    albumId,
    albumName
  ) => {
    console.log("<< Add New Song >>");
    setGlobalState((prevState) => {
      return {
        ...prevState,
        loading: {
          status: true,
          message: "Adding New Song...",
          error: false,
        },
      };
    });

    const songCoverUrl = await uploadFileToS3(cover, "images/songcovers");
    const songUrl = await uploadFileToS3(song, "songs");

    axios
      .post(`${backendURL}/add-new-song`, {
        songName: songName,
        song: songUrl,
        cover: songCoverUrl,
        contributors: contributors,
        albumId: albumId,
        albumName: albumName,
        songId: null,
      })
      .then((response) => {
        console.log("[Response] Add New Song: ", response.data);
        toast.success("Song Added Successfully.");
        setGlobalState((prevState) => {
          return {
            ...prevState,
            loading: {
              status: false,
              message: "",
              error: false,
            },
          };
        });
      })
      .catch((error) => {
        console.log("[Error] Add New Song: ", error);
        toast.error("Error Adding Song.");
        setGlobalState((prevState) => {
          return {
            ...prevState,
            loading: {
              status: false,
              message: "",
              error: false,
            },
          };
        });
      });
  };

  const getSongCount = async () => {
    console.log("<< Get Song Count >>");
    let songCount = 0;
    if (globalState.albums.length > 0) {
      globalState.albums.forEach(async (album) => {
        songCount += await album.songs.length;
        setGlobalState((prevState) => {
          return {
            ...prevState,
            songCount: songCount,
          };
        });
      });
    }
  };

  const getSongData = async (songId) => {
    console.log("<< Get Song Data >>", songId);
    if (globalState.web3 !== null) {
      try {
        const abi = JSON.parse(contractabi);
        const contract = new globalState.web3.eth.Contract(
          abi,
          globalState.contractAddress
        );
        const song = await contract.methods.getSong(songId).call();
        console.log("Song data:", song);
        return song;
      } catch (error) {
        console.error("Error:", error);
        throw new Error("Internal Server Error");
      }
    } else {
      // toast.error("Please connect your wallet");
      // navigate("/author-profile")
      const customWeb3 = new Web3(
        "https://polygon-mumbai.g.alchemy.com/v2/ihCRuJlD7rTgyVlLm4Mm-QKK4YVOvvky"
      );
      try {
        const contract = new customWeb3.eth.Contract(
          JSON.parse(contractabi),
          globalState.contractAddress
        );
        const song = await contract.methods.getSong(songId.toString()).call();
        console.log("Song data:", song);
        return song;
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const getOnChainSongData = async (albums) => {
    console.log("<< On Chain Song Data >>");
    let songArr = {};
    if (albums) {
      console.log("Opting for Parameter", albums, albums.length);
      if (albums.length > 0) {
        albums.forEach(async (album) => {
          album.songs.forEach(async (song) => {
            await getSongData(song.songId).then((songData) => {
              songArr[song.songId] = songData;
              setGlobalState((prevState) => {
                return {
                  ...prevState,
                  onChainSongData: songArr,
                };
              });
            });
          });
        });
        console.log("On Chain Song Data:", songArr);
      }
    } else {
      console.log("Opting for Global State", globalState.albums);
      if (globalState.albums.length > 0) {
        globalState.albums.forEach(async (album) => {
          album.songs.forEach(async (song) => {
            await getSongData(song.songId).then((songData) => {
              songArr[song.songId] = songData;
              setGlobalState((prevState) => {
                return {
                  ...prevState,
                  onChainSongData: songArr,
                };
              });
            });
          });
        });
        console.log("On Chain Song Data:", songArr);
      }
    }
  };

  const playSong = async (songId) => {
    console.log("<< Play Song >>", songId);
    axios
      .post(`${backendURL}/play-song`, {
        songId: songId,
      })
      .then((response) => {
        console.log("[Response] Play Song: ", response.data);
        stringToArrayBuffer(response.data.song)
          .then((result) => {
            console.log("String converted to ArrayBuffer:", result);
            const blob = new Blob([result], { type: "audio/mpeg" });
            const blobUrl = URL.createObjectURL(blob);
            console.log("Blob URL:", blobUrl);
            setGlobalState((prevState) => {
              return {
                ...prevState,
                liveSong: {
                  songData: blobUrl,
                },
              };
            });
          })
          .catch((error) => {
            console.error("Error converting string to ArrayBuffer:", error);
          });
      })
      .catch((error) => {
        console.log("[Error] Play Song: ", error);
      });
  };

  const getTotalSongStats = async () => {
    console.log("<< Get Total Song Stats >>");
    if (Object.keys(globalState.onChainSongData).length > 0) {
      let totalPlayCount = 0;
      let totalRoyalty = 0;
      Object.keys(globalState.onChainSongData).forEach((song) => {
        totalPlayCount += parseInt(globalState.onChainSongData[song][1]);
        totalRoyalty += parseInt(
          globalState.onChainSongData[song][2] * totalPlayCount
        );
      });
      if (isNaN(totalPlayCount)) totalPlayCount = 0;
      if (isNaN(totalRoyalty)) totalRoyalty = 0;
      setGlobalState((prevState) => {
        return {
          ...prevState,
          totalPlayCount: totalPlayCount,
          totalRoyalty: totalRoyalty,
        };
      });
      console.log("Total Song Count:", totalPlayCount, totalRoyalty);
    }
  };

  const getTotalSongStatsByAlbum = async (albumId) => {
    console.log("<< Get Total Song Count By Album >>");
    if (Object.keys(globalState.onChainSongData).length > 0) {
      let totalPlayCount = 0;
      let totalRoyalty = 0;
      Object.keys(globalState.onChainSongData).forEach((song) => {
        if (globalState.onChainSongData[song][1] === albumId) {
          totalPlayCount += parseInt(globalState.onChainSongData[song][2]);
          totalRoyalty += parseInt(
            globalState.onChainSongData[song][3] * totalPlayCount
          );
        }
      });
      setGlobalState((prevState) => {
        return {
          ...prevState,
          albumPlayCount: totalPlayCount,
          albumRoyalty: totalRoyalty,
        };
      });
      console.log("Total Song Count:", totalPlayCount, totalRoyalty);
    }
  };

  const getAll = async () => {
    console.log("<< Get All >>");
    axios.get(`${backendURL}/get-all-albums`).then((response) => {
      console.log("[Response] Get All Albums: ", response.data);
      setGlobalState((prevState) => {
        return {
          ...prevState,
          allAlbums: response.data.albums,
        };
      });
      getOnChainSongData(response.data.albums);
      getLikedSongs(response.data.albums);
    });
    axios.get(`${backendURL}/get-all-authors`).then((response) => {
      console.log("[Response] Get All Authors: ", response.data);
      setGlobalState((prevState) => {
        return {
          ...prevState,
          allAuthors: response.data,
        };
      });
    });
  };

  const getLikedSongs = async (data) => {
    let tempArr = [];
    let userLikedSongs = globalState.userObject.likedSongs;
    console.log("<< Get Liked Songs >>");
    data.forEach((album) => {
      album.songs.forEach((song) => {
        if (userLikedSongs.includes(song.songId)) {
          tempArr.push(song);
        }
      });
      setGlobalState((prevState) => {
        return {
          ...prevState,
          likedSongs: tempArr,
        };
      });
    });
  };

  const uploadFileToS3 = async (file, folder) => {
    console.log("<< Upload File To S3 >>");

    setGlobalState((prevState) => {
      return {
        ...prevState,
        loading: {
          status: true,
          message: "Uploading Data...",
          error: false,
        },
      };
    });

    console.log("++ Uploading file:", `"${backendURL}/get-presigned-url"`);

    try {
      const response = await axios.get(`${backendURL}/get-presigned-url`, {
        params: {
          fileName: file.name,
          fileType: file.type,
          folderName: folder,
        },
      });

      const { url, signedUrl } = response.data;

      console.log("Uploading file to S3:", response.data);

      await axios.put(signedUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      console.log("File uploaded successfully:", url);
      setGlobalState((prevState) => {
        return {
          ...prevState,
          loading: {
            status: false,
            message: "Creating New Album...",
            error: false,
          },
        };
      });
      return url;
    } catch (error) {
      console.error("Error uploading file:", error);
      setGlobalState((prevState) => {
        return {
          ...prevState,
          loading: {
            status: false,
            message: "Creating New Album...",
            error: false,
          },
        };
      });
      // Handle the error, such as displaying an error message
    }
  };

  const markValidPlayCount = async (songId) => {
    console.log("<< Mark Valid Play Count >>", songId);
    axios
      .post(`${backendURL}/mark-valid-play-count`, {
        songId: songId,
      })
      .then((response) => {
        console.log("[Response] Mark Valid Play Count: ", response.data);
      });
  };

  const getSongByUrl = async (songURL) => {
    console.log("<< Mark Valid Play Count >>", songURL);
    return axios
      .post("${backendURL}/get-song-by-url", {
        songURL: songURL,
      })
      .then((response) => {
        console.log("[Response] Song Data: ", response.data);
        return response.data;
      })
      .catch((error) => {
        console.error("[Error] Failed to fetch song data:", error);
        throw error;
      });
  };

  const getContributorData = async () => {
    console.log(
      "<< Get Contributor Data >>",
      globalState.userObject.walletAddress
    );
    return axios
      .post(`${backendURL}/contributor-data`, {
        walletAddress: globalState.userObject.walletAddress,
      })
      .then((response) => {
        console.log("[Response] Get Contributor Data: ", response.data);
        setGlobalState((prevState) => {
          return {
            ...prevState,
            contributorData: response.data,
          };
        });
        return response.data;
      });
  };

  useEffect(() => {
    // printSeconds();
    getSongCount();
  }, [globalState.albums]);

  useEffect(() => {}, []);

  useEffect(() => {
    console.log("<< ---- >>", globalState.userObject);
    if (globalState.userObject.role === "listener") {
      getAll();
    }
  }, [globalState.userObject]);

  useEffect(() => {
    getTotalSongStats();
  }, [globalState.onChainSongData]);

  useEffect(() => {
    if (globalState.authorAlbumView !== "") {
      getTotalSongStatsByAlbum(globalState.authorAlbumView);
    }
  }, [globalState.authorAlbumView]);

  return (
    <>
      <AppContext.Provider
        value={{
          globalState,
          setGlobalState,
          tools,
          checkJWTStatus,
          connectWallet,
          disconnectWallet,
          walletSignup,
          emailSignup,
          walletLogin,
          emailLogin,
          logout,
          updateUserObject,
          imageConverter,
          createAlbum,
          getAlbumsOfAuthor,
          addNewSong,
          getSongCount,
          getSongData,
          playSong,
          getOnChainSongData,
          getTotalSongStats,
          getTotalSongStatsByAlbum,
          getAll,
          uploadFileToS3,
          markValidPlayCount,
          getSongByUrl,
          getContributorData,
        }}
      >
        <Toaster
          toastOptions={{
            success: {
              style: {
                background: "#FF9A45",
                color: "#fff",
              },
            },
            error: {
              style: {
                background: "#ff0000",
                color: "#fff",
              },
            },
          }}
        />
        <div className="h-screen">
          {globalState.loading.status === true ? <Loading /> : null}
          {globalState.isAuthenticated === true ? (
            <Navbar page={children} />
          ) : (
            children
          )}
        </div>
      </AppContext.Provider>
    </>
  );
};
