import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";

import playlistIcon from "../assets/icons/playlist.svg";
import logoutIcon from "../assets/icons/logout.svg";
import albumsIcon from "../assets/icons/albums.svg";
import homeIcon from "../assets/icons/home.svg";
import profileIcon from "../assets/icons/Profile.svg";
import settingsIcon from "../assets/icons/Setting.svg";

function ContributorMenuOptions(props) {
  const { globalState, logout } = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <>
      <li>
        <a
          // href="/dashboard"
          className={
            window.location.pathname === "/dashboard"
              ? "flex items-center p-2 text-custom-primary rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:cursor-pointer"
              : "flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:cursor-pointer"
          }
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.3586 6.67534L11.9003 2.30867C10.8336 1.45867 9.16696 1.45034 8.10863 2.30034L2.65029 6.67534C1.86696 7.30034 1.39196 8.55034 1.55863 9.53367L2.60863 15.817C2.85029 17.2253 4.15863 18.3337 5.58363 18.3337H14.417C15.8253 18.3337 17.1586 17.2003 17.4003 15.8087L18.4503 9.52534C18.6003 8.55034 18.1253 7.30034 17.3586 6.67534ZM10.6253 15.0003C10.6253 15.342 10.342 15.6253 10.0003 15.6253C9.65863 15.6253 9.37529 15.342 9.37529 15.0003V12.5003C9.37529 12.1587 9.65863 11.8753 10.0003 11.8753C10.342 11.8753 10.6253 12.1587 10.6253 12.5003V15.0003Z"
              fill={
                window.location.pathname === "/dashboard"
                  ? "#FF9A45"
                  : "#7C8DB5"
              }
              fillOpacity={
                window.location.pathname === "/dashboard" ? "1" : "0.72"
              }
            />
            <defs>
              <linearGradient
                id="paint0_linear_515_305"
                x1="10.0016"
                y1="-6.66635"
                x2="-6.66263"
                y2="10.2837"
                gradientUnits="userSpaceOnUse"
              ></linearGradient>
            </defs>
          </svg>

          <span class="flex-1 ml-3 whitespace-nowrap">Home</span>
        </a>
      </li>

      <li>
        <a
          // href="/contributor-profile"
          className={
            window.location.pathname === "/contributor-profile"
              ? "flex items-center p-2 text-custom-primary rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:cursor-pointer"
              : "flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:cursor-pointer"
          }
          onClick={() => {
            navigate("/contributor-profile");
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.0016 11.317C17.2997 11.4753 17.5296 11.7253 17.6915 11.9753C18.0066 12.492 17.981 13.1253 17.6744 13.6837L17.0783 14.6837C16.7632 15.217 16.1755 15.5503 15.5709 15.5503C15.2728 15.5503 14.9407 15.467 14.6682 15.3003C14.4467 15.1587 14.1912 15.1087 13.9187 15.1087C13.0756 15.1087 12.3688 15.8003 12.3432 16.6253C12.3432 17.5837 11.5597 18.3337 10.5803 18.3337H9.42211C8.43422 18.3337 7.65072 17.5837 7.65072 16.6253C7.63369 15.8003 6.92683 15.1087 6.08372 15.1087C5.80268 15.1087 5.54719 15.1587 5.33428 15.3003C5.06176 15.467 4.72111 15.5503 4.43155 15.5503C3.81838 15.5503 3.23075 15.217 2.91565 14.6837L2.32802 13.6837C2.01292 13.142 1.99589 12.492 2.31099 11.9753C2.44725 11.7253 2.70274 11.4753 2.9923 11.317C3.23075 11.2003 3.38405 11.0087 3.52882 10.7837C3.95464 10.067 3.69915 9.12533 2.97526 8.70033C2.13215 8.22533 1.85963 7.16699 2.34506 6.34199L2.91565 5.35866C3.4096 4.53366 4.46562 4.24199 5.31725 4.72533C6.05817 5.12533 7.02051 4.85866 7.45484 4.15033C7.5911 3.91699 7.66775 3.66699 7.65072 3.41699C7.63369 3.09199 7.72737 2.78366 7.88918 2.53366C8.20428 2.01699 8.77487 1.68366 9.39656 1.66699H10.5974C11.2276 1.66699 11.7982 2.01699 12.1133 2.53366C12.2666 2.78366 12.3688 3.09199 12.3432 3.41699C12.3262 3.66699 12.4028 3.91699 12.5391 4.15033C12.9734 4.85866 13.9358 5.12533 14.6852 4.72533C15.5283 4.24199 16.5928 4.53366 17.0783 5.35866L17.6489 6.34199C18.1428 7.16699 17.8703 8.22533 17.0187 8.70033C16.2948 9.12533 16.0393 10.067 16.4736 10.7837C16.6099 11.0087 16.7632 11.2003 17.0016 11.317ZM7.59111 10.0087C7.59111 11.317 8.67268 12.3587 10.0097 12.3587C11.3468 12.3587 12.4028 11.317 12.4028 10.0087C12.4028 8.70033 11.3468 7.65033 10.0097 7.65033C8.67268 7.65033 7.59111 8.70033 7.59111 10.0087Z"
              fill={
                window.location.pathname === "/contributor-profile"
                  ? "#FF9A45"
                  : "#7C8DB5"
              }
              fill-opacity={
                window.location.pathname === "/contributor-profile"
                  ? "1"
                  : "0.72"
              }
            />
          </svg>

          <span class="flex-1 ml-3 whitespace-nowrap">Profile</span>
        </a>
      </li>
      <li>
        <a
          // href="#"
          class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
          onClick={() => {
            logout();
          }}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 711 712"
            fill="#7C8DB5"
            fill-opacity="0.72"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M526.201 0H433.736C319.933 0 248.806 71.127 248.806 184.93V328.962H471.078C485.659 328.962 497.75 341.054 497.75 355.635C497.75 370.216 485.659 382.308 471.078 382.308H248.806V526.34C248.806 640.143 319.933 711.27 433.736 711.27H525.845C639.649 711.27 710.776 640.143 710.776 526.34V184.93C711.131 71.127 640.004 0 526.201 0Z"
              fill="#7C8DB5"
              fill-opacity="0.72"
            />
            <path
              d="M90.9537 328.956L164.57 255.341C169.905 250.006 172.394 243.249 172.394 236.492C172.394 229.735 169.905 222.622 164.57 217.643C154.257 207.33 137.186 207.33 126.873 217.643L7.73506 336.78C-2.57835 347.093 -2.57835 364.164 7.73506 374.477L126.873 493.615C137.186 503.928 154.257 503.928 164.57 493.615C174.884 483.301 174.884 466.231 164.57 455.917L90.9537 382.301H248.856V328.956H90.9537Z"
              fill="#7C8DB5"
              fill-opacity="0.72"
            />
          </svg>

          <span class="flex-1 ml-3 whitespace-nowrap">Log Out</span>
        </a>
      </li>
    </>
  );
}

export default ContributorMenuOptions;
