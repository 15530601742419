import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";

import SongCard from "../components/SongCard";

function LikedSongs(props) {
  const { globalState, imageConverter } = useContext(AppContext);
  const [likedSongs, setLikedSongs] = useState([]);
  const navigate = useNavigate();

  const processLikedSongs = () => {
    console.log("Processing Liked Songs2");
    console.log(
      "**like**",
      globalState.allAlbums,
      globalState.userObject.likedSongs
    );
    setLikedSongs([]);
    let tempArr = [];
    if (globalState.allAlbums.length > 0) {
      globalState.allAlbums.forEach((album) => {
        album.songs.forEach((song) => {
          console.log(
            "Checking if the song is liked...",
            song.songId,
            globalState.userObject.likedSongs,
            globalState.userObject.likedSongs.includes(song.songId)
          );
          if (globalState.userObject.likedSongs.includes(song.songId)) {
            const {
              songId,
              songName,
              songCover,
              albumName,
              albumId,
              timestamp,
              playCounts,
            } = song;
            const card = (
              <SongCard
                key={songId}
                songId={songId}
                songName={songName}
                songCover={imageConverter(songCover)}
                albumName={albumName}
                albumId={albumId}
                timestamp={timestamp}
                playCount={playCounts}
                showPlayCount={true}
                showLikeButton={false}
                isLiked={
                  globalState.userObject.likedSongs.includes(songId)
                    ? true
                    : false
                }
              />
            );
            tempArr.push(card);
            tempArr.sort((a, b) => b.props.playCount - a.props.playCount);
            setLikedSongs(tempArr);
            console.log("Liked Song Cards", tempArr);
          }
        });
      });
    }
  };

  useEffect(() => {
    console.log("Processing Liked Songs");
    processLikedSongs();
  }, [globalState]);

  return (
    <div className="flex flex-col justify-between items-center p-4 px-6 w-full bg-custom-white rounded-3xl mt-6">
      <div className="flex flex-row justify-between w-full items-center my-3">
        <p className="text-md font-bold">Liked Songs</p>
        <p
          className="text-custom-text-gray text-sm hover:cursor-pointer"
          onClick={() => {
            navigate("/liked-songs");
          }}
        >
          See All
        </p>
      </div>
      <div className="flex flex-col items-center space-y-3 w-full mt-2">
        {/* <SongCard showPlayCount={false} songName="She" /> */}
        {likedSongs.length > 0 ? (
          likedSongs
        ) : (
          <p className="text-md font-bold mb-4 text-center">No Liked Songs</p>
        )}
      </div>
    </div>
  );
}

export default LikedSongs;
