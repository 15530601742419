import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const DashboardChart = (props) => {
  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const generateChartData = (upperCount) => {
    const hours = 12;
    const data = [];

    let currentValue = getRandomNumber(15, upperCount - 1);
    let stepSize = (upperCount - currentValue) / (hours - 2);

    data.push(currentValue);

    for (let i = 0; i < hours - 2; i++) {
      currentValue += stepSize;
      data.push(Math.floor(currentValue));
    }

    data.push(upperCount);

    return data;
  };

  const bgcolor = [
    "rgba(192, 75, 192, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(192, 192, 75, 0.2)",
    "rgba(192, 75, 75, 0.2)",
    "rgba(75, 192, 75, 0.2)",
    "rgba(75, 75, 192, 0.2)",
    "rgba(192, 75, 192, 0.2)",
    "rgba(192, 192, 192, 0.2)",
    "rgba(128, 128, 128, 0.2)",
    "rgba(0, 0, 0, 0.2)",
    "rgba(255, 0, 0, 0.2)",
    "rgba(0, 255, 0, 0.2)",
    "rgba(0, 0, 255, 0.2)",
    "rgba(255, 255, 0, 0.2)",
    "rgba(255, 0, 255, 0.2)",
    "rgba(0, 255, 255, 0.2)",
    "rgba(255, 128, 0, 0.2)",
    "rgba(255, 0, 128, 0.2)",
    "rgba(128, 255, 0, 0.2)",
    "rgba(0, 128, 255, 0.2)",
  ];

  const bordercolor = bgcolor.map((color) => {
    return color.replace(",0.2)", ",1)");
  });

  let counter = 0

  const datasets = props.songs.map((song) => {
    counter++
    return {
      label: song.name,
      data: generateChartData(song.upperCount),
      fill: true,
      backgroundColor: bgcolor[counter - 1],
      borderColor: bordercolor[counter - 1],
    };
  });

  const data = {
    labels: Array.from({ length: 12 }, (_, i) => `${i + 1} hr`),
    datasets,
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        display: false,
      },
      title: {
        display: false,
        text: "Play Count",
      },
    },
  };

  return <Line options={options} data={data} />;
};

export default DashboardChart;
