import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../AppContext";

import Titlebar from "../../components/Titlebar";
import DetailedSongCard from "../../components/DetailedSongCard";

function LikedSongs(props) {
  const { globalState, imageConverter } = useContext(AppContext);
  const [likedSongs, setLikedSongs] = useState([]);

  // const processLikedSongs = () => {
  //   console.log("Processing Liked Songs", globalState.likedSongs);
  //   let tempArr = [];
  //   if (globalState.likedSongs.length > 0) {
  //     globalState.likedSongs.forEach((song) => {
  //       const { songId, songName, songCover, albumName, albumId, timestamp } =
  //         song;
  //       if (globalState.onChainSongData[songId] !== undefined) {
  //         const playCount = globalState.onChainSongData[songId][2];
  //         const card = (
  //           <DetailedSongCard
  //             key={songId}
  //             songId={songId}
  //             songName={songName}
  //             songCover={imageConverter(songCover)}
  //             albumName={albumName}
  //             albumId={albumId}
  //             timestamp={timestamp}
  //             playCount={playCount}
  //             showPlayCount={true}
  //           />
  //         );
  //         tempArr.push(card);
  //       }
  //     });
  //     setLikedSongs(tempArr);
  //     console.log("Liked Songs", tempArr);
  //   }
  // };

  const processLikedSongs = () => {
    console.log(
      "Processing Song Cards",
      globalState.onChainSongData,
      globalState.allAlbums
    );
    let tempArr = [];
    if (globalState.allAlbums.length > 0) {
      globalState.allAlbums.forEach((album) => {
        album.songs.forEach((song) => {
          const {
            songId,
            songName,
            songCover,
            albumName,
            albumId,
            timestamp,
            playCounts,
          } = song;
          if (globalState.userObject.likedSongs.includes(songId)) {
            const card = (
              <DetailedSongCard
                key={songId}
                songId={songId}
                songName={songName}
                songImage={imageConverter(songCover)}
                albumName={albumName}
                albumId={albumId}
                timestamp={timestamp}
                playCount={playCounts}
                showPlayCount={true}
                showReleased={true}
                showLike={false}
                isLiked={
                  globalState.userObject.likedSongs.includes(songId)
                    ? true
                    : false
                }
                releaseDate={timestamp}
              />
            );
            tempArr.push(card);
          }
        });
      });
      tempArr.sort((a, b) => b.props.playCount - a.props.playCount);
      setLikedSongs(tempArr);
      console.log("Song Cards", tempArr);
    }
  };

  useEffect(() => {
    console.log("Liked Songs");
    processLikedSongs();
  }, [globalState]);

  return (
    <>
      <Titlebar 
        pageTitle="Liked Songs"
        showSearchBar={false}
      />
      <div className="flex flex-col lg:justify-between items-center p-4 px-6 bg-custom-white rounded-3xl mt-6 md:w-full md:p-8 lg:w-full lg:p-8 ">
        <div className="flex flex-row justify-between w-full items-center ">
          <p className="text-md font-bold">Liked Songs</p>
        </div>
        {likedSongs.length > 0 ? (
          <div className="flex flex-col items-center space-y-6 w-full mt-6">
            {likedSongs}
          </div>
        ) : (
          <div className="flex flex-col justify-center  items-center space-y-2 text-center py-16">
            <p className="text-2xl lg:text-4xl md:text-4xl  font-bold text-custom-text-gray">
              No Songs to display
            </p>
            {/* <p className="lg:text-md md:text-md text-sm  text-custom-text-gray">(Songs liked by you will be added here)</p> */}
          </div>
        )}
      </div>
    </>
  );
}

export default LikedSongs;
