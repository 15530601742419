import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { useNavigate } from "react-router-dom";
import { Jazzicon } from "@ukstv/jazzicon-react";
import { toast } from "react-hot-toast";

import Titlebar from "../../components/Titlebar";

import ArtistCard from "../../components/ArtistCard";
import SongOverlay from "./SongOverlay";
import AlbumCard from "../../components/AlbumCard";

function MyAlbums(props) {
  const {
    globalState,
    setGlobalState,
    tools,
    checkJWTStatus,
    updateUserObject,
    imageConverter,
    getAlbumsOfAuthor,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [albumCards, setAlbumCards] = useState([]);

  const initAuthCheck = async () => {
    console.log("[+] Checking if user is authenticated", globalState);
    if (globalState.isAuthenticated === false) {
      console.log("[+] User is not authenticated");
      const status = await checkJWTStatus("Author Profile");
      if (status === false) {
        navigate("/login");
      } else {
        getAlbumsOfAuthor(globalState.userObject.id)
      }
    } else {
      getAlbumsOfAuthor(globalState.userObject.id);
    }
  };

  const processAlbumCards = () => {
    let tempArr = [];
    if (globalState.albums.length > 0) {
      const card = globalState.albums.map((album) => {
        return (
          <AlbumCard
            albumName={album.albumName}
            authorName={album.authorName}
            albumCover={imageConverter(album.albumCover)}
            albumId={album.albumId}
          />
        );
      });
      tempArr.push(card);
      setAlbumCards(tempArr);
    }
  };

  useEffect(() => {
    processAlbumCards();
  }, [globalState.albums]);

  useEffect(() => {
    initAuthCheck();
  }, []);

  return (
    <>
      <Titlebar
        showIcon={false}
        showSearchBar={false}
        pageTitle={"My Albums"}
      />
      {albumCards.length > 0 ? (
        <div className="flex flex-col  p-4 px-6 bg-custom-white rounded-3xl mt-6 md:w-full md:p-8 lg:w-full lg:p-8">
          <div className="grid grid-cols-2 md:grid-cols-3  lg:grid-cols-5 w-full mt-4">
            {albumCards}
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full justify-center mt-16">
          <p className="text-md font-bold text-center text-3xl text-black/30">
            No Albums Found
          </p>
        </div>
      )}
    </>
  );
}

export default MyAlbums;
