import React from "react";
import { useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar";

import backIcon from "../assets/icons/backIcon.svg";

function Titlebar(props) {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex lg:flex-row md:flex-row flex-col justify-between items-center px-2  w-full">
        <div className="flex flex-row space-x-4 items-center">
          {props.showIcon === true ? (
            <img
              src={backIcon}
              className="w-8 h-8 hover:cursor-pointer"
              onClick={() => {
                if (props.previousPage) {
                  navigate(props.previousPage);
                } else {
                  navigate("/my-albums");
                }
              }}
            />
          ) : null}
          <p className="text-2xl font-bold mb-0 lg:mb-0 md:mb-0">
            {props.pageTitle ? props.pageTitle : "Dashboard"}
          </p>
        </div>
        {/* <p>{props.pageTitle}</p>  */}
        {props.showSearchBar === true ? <SearchBar /> : null}
      </div>
    </>
  );
}

export default Titlebar;
