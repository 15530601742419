const trimString = (str, start, end) => {
  return str.slice(0, start) + "..." + str.slice(str.length - end);
};

const checkEmailFormat = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

const convertTimestamp = (timestamp) => {
  const date = new Date(timestamp);

  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
};

export { trimString, checkEmailFormat, convertTimestamp };
