import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { useNavigate } from "react-router-dom";

import Titlebar from "../../components/Titlebar";
import TopArtists from "../../components/TopArtists";
import TopSongs from "../../components/TopSongs";
import LikedSongs from "../../components/LikedSongs";
import TopAlbums from "../../components/TopAlbums";

function ListenerDashboard(props) {
  const { globalState, checkJWTStatus, imageConverter, getAlbumsOfAuthor } =
    useContext(AppContext);
  const initAuthCheck = async () => {
    console.log("[+] Checking if user is authenticated", globalState);
    if (globalState.isAuthenticated === false) {
      console.log("[+] User is not authenticated");
      const status = await checkJWTStatus("Author Dashoboard");
      if (status === false) {
        navigate("/login");
      } else {
        getAlbumsOfAuthor(globalState.userObject.id);
      }
    } else {
      getAlbumsOfAuthor(globalState.userObject.id);
    }
  };

  useEffect(() => {
    initAuthCheck();
  }, []);

  return (
    <>
      <Titlebar showSearchBar={false} />
      <TopArtists />
      <div className="flex flex-col  lg:flex-row lg:space-x-4 items-start">
        <TopSongs />
        <LikedSongs />
      </div>
      {/* <TopAlbums /> */}
    </>
  );
}

export default ListenerDashboard;
